<template>
  <div class="modal__authors-table-row modal__authors-table-row-new">
    <input v-model="author.name" @input="author.name = upperCaseFirstLetter(author.name)" class="name" type="text" placeholder="Фамилия">
    <input v-model="author.surname" @input="author.surname = upperCaseFirstLetter(author.surname)" class="surname" type="text" placeholder="Имя">
    <input v-model="author.patronymic" @input="author.patronymic = upperCaseFirstLetter(author.patronymic)" class="patronymic" type="text" placeholder="Отчество">
    <input v-model="author.city" @input="author.city = upperCaseFirstLetter(author.city)" class="city" type="text" placeholder="Город">
    <button v-on:click="saveNewAuthor" class="modal__authors-new-save">Сохранить</button>
  </div>

</template>

<script>
import Author from "@/models/author";

export default {
  name: "AddNewAuthor",
  data() {
    return {
      author: new Author('','','','','')
    }
  },
  methods: {
    saveNewAuthor() {
      this.$parent.filteredAuthors = this.$store.getters['modalAuthors/filterAuthor']('')
      this.$store.dispatch('modalAuthors/addNewAuthor', this.author)
      this.$parent.$store.dispatch('modalAuthors/getAllAuthors');
      this.$parent.isAuthorAddVisible = false
      this.$parent.$store.dispatch('modalAuthors/closeModal');
      setTimeout(() => {
         this.$parent.$store.dispatch('modalAuthors/showModal')
      }, 100)

    },
    upperCaseFirstLetter: function (value) {
      return value.replace(value.charAt(0), value.charAt(0).toUpperCase())
    }
  }
}
</script>

<style scoped>

</style>