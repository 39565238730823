<template>
  <div class="filter__select">
      <div class="filter__select-title">
        <p  @click="optionsVisible=!optionsVisible">
            {{selectFilterData.filterName}}
             <font-awesome-icon icon="angle-down" v-if="!optionsVisible"/>
             <font-awesome-icon icon="angle-up" v-else/>
        </p>
      <transition name="slide-fade-options">
          <div class="filter__select-options"
              v-if="optionsVisible">
              <div v-for="item in selectFilterData.filterValuesList"
                   :key="item.id"
                   @click="setFilterSelected(item); removeSelectedValue(item.id)">
                  {{item.value}}
              </div>
          </div>
      </transition>
    </div>
    <div class="filter__selected-options-container">
          <filter-selected-block :param-data="filteredValuesList"></filter-selected-block>
      </div>
  </div>
</template>

<script>
import filterSelectedBlock from "./filterSelectedBlock";

export default {
  name: "selectTypeFilter",
  props: ['selectFilterData'],
  data(){
      return{
          optionsVisible: false,
          filteredValuesList: null
      }
  },
  mounted(){
        document.addEventListener('click', this.handleClickOutside);
    },
  beforeDestroy(){
        document.removeEventListener('click', this.closeSelect(), false)
    },
  methods: {
    handleClickOutside(e) {
        if (!this.$el.contains(e.target)) {
            this.optionsVisible  =  false;
          }
        },
    closeSelect(){
      this.optionsVisible =  false
      },
     setFilterSelected(item) {
        this.optionsVisible = false
        let routeObject = {}
        routeObject[this.selectFilterData.filterKey] = (this.selectFilterData.filterKey=='article_heading_id')?item.id : item.value;

        this.$route.meta.query={...this.$route.meta.query, ...routeObject}
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
     },
     
     removeSelectedValue(removingItemIndex){
        this.filteredValuesList = this.selectFilterData.filterValuesList.filter(item =>
            item.id === removingItemIndex)
     },
     resetFilters(){
        this.filteredValuesList = null;
        // Delete parm from query string
        delete this.$route.meta.query[this.selectFilterData.filterKey];

        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query); ///###
     }
  },
  components: {
     filterSelectedBlock
  }
}
</script>

<style scoped>

</style>