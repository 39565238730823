<template>
     <div class="filter__search-text">
       <div class="filter__search-text-container">
           <label :for="labelID">{{labelText}}:</label>
           <input :label="labelID" :maxlength="maxSymbolLength"
                 :placeholder="inputText" 
                 v-model.trim="filteredValue"
                 >
        </div>
        <div class="filter__selected-options-container">
          <filter-entered-text-block :param-data="enteredText"></filter-entered-text-block>
      </div>
      </div>
</template>

<script>
import filterEnteredTextBlock from "./filterEnteredTextBlock";
import _ from 'lodash';

export default {
  name: "textTypeFilter",
  props: ['labelID', 'labelText','inputText'],
  data(){
    return{
      enteredText: null,
      filteredValue: null,
      maxSymbolLength: 50
    }
  },
  components: {
     filterEnteredTextBlock
  },
  created() {
    this.debouncedGetEntredText = _.debounce(this.debounceSetValue, 1000)
  },
  watch:{
    filteredValue(){
      if(this.filteredValue!=null||this.filteredValue!=''){
        this.debouncedGetEntredText();
        }
      },
      
  },
  methods:{
    setEmptyValue(){
      this.enteredText = null;
      this.filteredValue = null;
    },
    debounceSetValue(){
        this.enteredText =(this.filteredValue=='')?this.enteredText=null:this.filteredValue;
         if(this.enteredText==null){
            this.resetFilters();
         }else{
            let routeObject = {}
            routeObject[this.labelID] = this.enteredText;
            this.$route.meta.query={...this.$route.meta.query, ...routeObject}
            this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
        }
    },
     resetFilters(){
        this.setEmptyValue();
        delete this.$route.meta.query[this.labelID];
        
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);///###
     }
  }

}
</script>

<style scoped>

</style>