import BaseRegistryState from "./BaseRegistryState";
import ArticleService from "@/services/articles.service"
import moment from "moment";

const state = {
    ...BaseRegistryState.state,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD")
}



const mutations = {
    ...BaseRegistryState.mutations,
    setStartDate(state, newDate) {
        state.startDate = newDate
    },
    setEndDate(state, newDate) {
        state.endDate = newDate
    }
}

const actions = {
    getArticleData({commit}) {
       commit('setDataLoadedFlagStatus', true)
       return ArticleService.getPublishedArticlesStat(
           {
               startDate: state.startDate,
               endDate: state.endDate
           }
       ).then(result => {
           commit('setLoadedData', result.data)
           commit('setDataLoadedFlagStatus', false)
       })
    },
    changeStartDate({commit, dispatch}, data) {
        commit('setStartDate', data)
        dispatch('getArticleData')
    },
    changeEndDate({commit, dispatch}, data){
        commit('setEndDate', data)
        dispatch('getArticleData')
    }
}

export const articleRegistryPublishedState = {
    namespaced: true,
    state,
    actions,
    mutations
}