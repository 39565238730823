<template>
    <div class="barchart__header-filter-params">
         <label for="filterPeriodFrom">Дата от:</label>
         <input id="filterPeriodFrom" type="date"
                :value="startDate"
                v-on:change="changeDatePeriod('startDate', $event.target)">
         <label for="filterPeriodTo">Дата до:</label>
         <input id="filterPeriodTo" type="date"
                :value="endDate"
                v-on:change="changeDatePeriod('endDate', $event.target)">

     </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "statisticLineChartPeriodFilter",
  computed: mapState ({
      startDate: state => state.analyticsDashboard.requestQuery.startDate,
      endDate: state => state.analyticsDashboard.requestQuery.endDate,
  }),
  methods: {
     changeDatePeriod(queryParamName, event) {
        this.$store.dispatch('analyticsDashboard/setRequestProperties',
      {
              'keyToUpdate': queryParamName,
              'valueToUpdate': event.value
            }
        )
        this.$store.dispatch('analyticsTable/setRequestProperties',
      {
              'keyToUpdate': queryParamName,
              'valueToUpdate': event.value
            }
        
        )

     }
  }

}
</script>

<style scoped>

</style>