<template>
            <div class="article-registry__table-row article-registry__table-hovered" 
                >
            <div class="article-registry__table-sell--id" v-html="ArticleData.id">№</div>
            <div class="article-registry__table-sell--id"  v-html="ArticleData.tb" >ТБ</div>
            <div class="article-registry__table-sell--medium">
                <p v-for="author in ArticleData.author"
                     v-bind:key = "author.id"
                    > {{ author.name }} 
                      {{ author.surname.charAt(0) + '.,'}}
                      {{author.city }}
                    </p>
            </div>
            <div class="article-registry__table-sell--heading" v-html="ArticleData.article_name" >Название</div>
            <div class="article-registry__table-sell--heading" v-html="articleDateAdded">Дата регистрации статьи в реестре</div>
            <div class="article-registry__table-sell--heading" v-html="ArticleData.plagiat_percent">% уникальности перед публикацией</div>
            <div class="article-registry__table-sell--heading" v-html="articleDatePublish">Дата первой публикации</div>
            <div class="article-registry__table-sell--heading" >
              <!-- <p>{{ArticleRow}}</p> -->
               <a :href="`${ArticleRow.article_link}`">{{ArticleRow.article_link}}</a>
              <!-- <a :href="`${ArticleData.article_link}`">{{ArticleData.article_link=='article'?'':ArticleData.article_link}}</a> -->
              <!-- <br>
              <a v-if="ArticleData.article_social_links!=null" :href="`${'https://habr.com/ru/post/'+ArticleData.article_social_links.habr_post_id}`">{{'https://habr.com/ru/post/'+ArticleData.article_social_links.habr_post_id}}</a> -->
            </div>
            <div class="article-registry__table-sell--heading" v-html="dateCheck"></div>
            <div class="article-registry__table-sell--heading" v-html="ArticleRow.verification_stage">этап</div> 
            <div class="article-registry__table-sell--heading"><a :href="`${ArticleRow.link}`">{{ArticleRow.link=='https://service.newtechaudit.ru/monitoring'?'':ArticleRow.link}}</a></div>
            <div class="article-registry__table-sell--heading" v-html="ArticleRow.percentage_borrowing">% заимствование статьи</div>
       </div>




</template>

<script>

// import Article from "@/models/article";
import moment from 'moment';

export default {
  name: "ArticleRow",
  props: ['ArticleRow', 'ArticleData'],
  data() {
    return {
      active: 'activity__registry-highlighting',
      // article: new Article(this.ArticleRow),
      articleTB: '',
      articleDateAdded:'',
      articleDatePublish:'',
      dateCheck:''
    }
  },
  created() {
      this.dateCheck = moment(new Date(this.ArticleRow.date_check)).utc().format("DD-MM-YYYY")
    if(this.ArticleData.tb_responsible_name !== null) {
      this.articleTB = this.ArticleData.tb_responsible_name + ', '
    }
    if(this.ArticleData.tb_responsible !== null) {
        this.articleTB += this.ArticleData.tb_responsible
    }
    this.articleDateAdded = moment(new Date(this.ArticleData.date_added)).utc().format("DD-MM-YYYY")
    if(this.ArticleData.date_published !== null) {
      this.articleDatePublish = moment(new Date(this.ArticleData.date_published)).utc().format("DD-MM-YYYY")
    }
  },
  methods:{
      formatData(date){
        this.dateCheck = moment(new Date(date)).format("DD-MM-YYYY")
        console.log(date)
        return this.dateCheck
      }
  }
}

</script>
