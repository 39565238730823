<template>
      <div>
          <p> Уникальность статьи <span v-html="articlePlagiat"></span> %</p>
      </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "ArticlePlagiat",
  computed: mapState ({
    articlePlagiat: state => state.modalArticle.article.plagiat_percent,
  }),
}
</script>

<style scoped>

</style>