import axios from "axios";
import authHeader from "@/services/auth-header";
import moment from "moment";
import config from '@/services/config.service'

const API_URL = config['API_LOCATION'] + 'api/activity/'

class ActivityService {

    async getAllActivity(query) {
        return await axios.get(API_URL + 'all', {
            params: query
        })
    }
    

    async getSingleActivity(id) {
        return await axios.get(API_URL + id, {
            headers: authHeader()
        })
    }

    async getTypes() {
        return await axios.get(API_URL + 'list-activity-types/', {
            headers: authHeader()
        })
    }

    async saveActivityForm(activity) {
        if (activity.id === undefined || activity.id === 'null') {
            let activityToCreate = {
                    tb: activity.tb,
                    author: activity.author,
                    activity_type: activity.activity_type,
                    activity_block: activity.activity_block,
                    activity_effect: activity.name_prj+'.'+activity.veha_prj,
                    activity_project_name: activity.activity_project_name,
                    activity_project_veha: activity.activity_project_veha,
                    activity_full_descriprion: activity.activity_full_descriprion,
                    activity_name: activity.activity_name,
                    activity_date: moment(activity.activity_date).format('YYYY-MM-DD HH:mm'),
                    activity_type_id: { id: activity.activity_type_id},
                    points: activity.points

            }
            return await axios.post(API_URL + 'create', activityToCreate, {
                headers: authHeader()
            })
        }

        else {
            let activityToUpdate = {
                    activity: {
                        author: activity.author,
                        tb: activity.tb,
                        additional_points: activity.additional_points,
                        commentary_ca: activity.commentary_ca,
                        activity_type: activity.activity_type,
                        activity_name: activity.activity_name,
                        activity_block: activity.activity_block==''?activity.activity_block="-":activity.activity_block,
                        activity_effect: activity.name_prj+'.'+activity.veha_prj,
                        activity_project_name: activity.activity_project_name==''?activity.activity_project_name="-":activity.activity_project_name,
                        activity_project_veha: activity.activity_project_veha==''?activity.activity_project_veha="-":activity.activity_project_veha,
                        activity_full_descriprion: activity.activity_full_descriprion==''?activity.activity_full_descriprion="-":activity.activity_full_descriprion,
                        activity_date: moment(activity.activity_date).format('YYYY-MM-DD HH:mm'),
                        activity_status: activity.activity_status,
                        activity_type_id: { id: activity.activity_type_id.id},
                        points: activity.points
                    }
                    
            }

            console.log(activityToUpdate)
            return await axios({
                method:'put',
                url: API_URL + 'update/' + activity.id,
                headers: authHeader(),
                data: activityToUpdate,
            })
        }
    }
    async deleteActivityForm(id) {
        return await axios.delete(API_URL + id, {
            headers: authHeader()
        })
    }
}

export default new ActivityService();
