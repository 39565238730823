<template>
  <div class="modal__techs-table-row modal__techs-table-row-new">
    <input v-model="tech.name" @input="tech.name = upperCaseFirstLetter(tech.name)" class="name" type="text" placeholder="Фамилия">
    <input v-model="tech.surname" @input="tech.surname = upperCaseFirstLetter(tech.surname)" class="surname" type="text" placeholder="Имя">
    <input v-model="tech.patronymic" @input="tech.patronymic = upperCaseFirstLetter(tech.patronymic)" class="patronymic" type="text" placeholder="Отчество">
    <!-- <input v-model="tech.city" @input="tech.city = upperCaseFirstLetter(tech.city)" class="city" type="text" placeholder="Город"> -->
    <button v-on:click="saveNewTech" class="modal__techs-new-save">Сохранить</button>
  </div>

</template>

<script>
import Techspec from "@/models/tech";

export default {
  name: "AddNewTech",
  data() {
    return {
      tech: new Techspec('','','','','')
    }
  },
  methods: {
    saveNewTech() {
      this.$parent.filteredTechs = this.$store.getters['modalTechs/filterTech']('')
      this.$store.dispatch('modalTechs/addNewTech', this.tech)
      this.$parent.$store.dispatch('modalTechs/getAllTechs');
      this.$parent.isTechAddVisible = false
      this.$parent.$store.dispatch('modalTechs/closeModal');
      setTimeout(() => {
         this.$parent.$store.dispatch('modalTechs/showModal')
      }, 100)

    },
    upperCaseFirstLetter: function (value) {
      return value.replace(value.charAt(0), value.charAt(0).toUpperCase())
    }
  }
}
</script>

<style scoped>

</style>