import ArticleService from '@/services/articles.service';
import Article from '@/models/article';
import { getTags } from '../utils/getTags';

export const modalArticle = {
  namespaced: true,
  state: () => ({
    article: [],
    authors: [],
    techspecs: [],
    modalArticleLoaded: false,
    isArticleModalVisible: false,
    modalAuthorInvalid: false,
  }),

  mutations: {
    addArticleTech(state, tech) {
      let isDuplicateFound = false;
      if (state.techspecs.length > 0) {
        for (const value of Object.entries(state.techspecs)) {
          if (parseInt(value[1].id) === parseInt(tech.id)) {
            isDuplicateFound = true;
            break;
          }
        }
      }
      if (!isDuplicateFound) {
        state.techspecs.push(tech);
      }
    },
    removeArticleTech(state, techID) {
      state.techspecs.splice(
        state.techspecs.findIndex(function (i) {
          return i.id === techID;
        }),
        1
      );
    },
    addArticleAuthor(state, author) {
      let isDuplicateFound = false;
      if (state.authors.length > 0) {
        for (const value of Object.entries(state.authors)) {
          if (parseInt(value[1].id) === parseInt(author.id)) {
            isDuplicateFound = true;
            break;
          }
        }
      }
      if (!isDuplicateFound) {
        state.authors.push(author);
      }
    },
    removeArticleAuthor(state, authorID) {
      state.authors.splice(
        state.authors.findIndex(function (i) {
          return i.id === authorID;
        }),
        1
      );
    },
    getArticleData(state, articleLoadedData) {
      state.article = articleLoadedData;
      state.authors = articleLoadedData.author;
      state.techspecs = articleLoadedData.tech_specialist;
    },
    resetModalState(state) {
      state.article = [];
      state.authors = [];
      state.techspecs = [];
      state.modalArticleLoaded = false;
      state.isArticleModalVisible = false;
      state.modalAuthorInvalid = false;
      document.body.classList.remove('modal--overflow-hidden');
    },
    loadingModalArticle(state, newLoadingStatus) {
      state.modalArticleLoaded = newLoadingStatus;
    },
    isModalArticleVisible(state, newStatus) {
      state.isArticleModalVisible = newStatus;
      document.body.classList.add('modal--overflow-hidden');
    },
    modalAuthorInvalid(state, articleLoadedData) {
      state.modalAuthorInvalid = true;
      state.article = articleLoadedData;
      state.authors = articleLoadedData.author;
    },
    createNewArticle(state) {
      state.article = new Article({ id: 'null' });
      console.log(state.article);
    },
  },
  actions: {
    addArticleAuthor({ commit }, author) {
      commit('addArticleAuthor', author);
    },
    removeArticleAuthor({ commit }, authorID) {
      commit('removeArticleAuthor', authorID);
    },
    removeArticleTech({ commit }, techID) {
      commit('removeArticleTech', techID);
    },
    getArticleData({ commit }, id) {
      commit('isModalArticleVisible', true);
      commit('loadingModalArticle', true);
      return ArticleService.getSingleArticle(id)
        .then((result) => {
          const rule_tag = getTags('rule', result.data.tag);
          const tools_tag = getTags('tools', result.data.tag);
          const libs_tag = getTags('libs', result.data.tag);
          commit('loadingModalArticle', false);
          commit('getArticleData', {
            ...result.data,
            rule_tag,
            tools_tag,
            libs_tag,
          });
        })
        .catch((error) => {
          commit('loadingModalArticle', false);
          commit('modalAuthorInvalid', error.response.data);
        });
    },
    createNewArticle({ commit }) {
      commit('isModalArticleVisible', true);
      commit('createNewArticle');
    },
    saveArticleData({ commit, state }, data) {
      commit('loadingModalArticle', true);

      return ArticleService.saveArticleForm(
        data.data,
        state.authors,
        state.techspecs
      )
        .then((result) => {
          if (result.data.id) {
            return ArticleService.uploadArticleFile(
              result.data.id,
              data.file
            ).then(() => {
              commit('loadingModalArticle', false);
            });
          } else {
            if (data.file) {
              return ArticleService.uploadArticleFile(
                result.data.success,
                data.file
              ).then(() => {
                commit('loadingModalArticle', false);
              });
            }
          }
        })
        .catch((error) => console.log(error));
    },
    addSocaialWebs() {},

    addTech({ commit }, data) {
      commit('addArticleTech', data);
    },
    addAuthor({ commit }, data) {
      commit('addArticleAuthor', data);
    },
    closeArticleModal({ commit, dispatch }, query) {
      commit('resetModalState');
      dispatch('ArticlesTable/getArticles', query, { root: true });
    },
  },
  getters: {
    modalArticleData(state) {
      return state.article;
    },
    modalArticleLoaded(state) {
      return state.modalArticleLoaded;
    },
    modalArticleForbidden(state) {
      return state.articleAuthorNotValid;
    },
  },
};
