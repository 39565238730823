<template>
  <div class="modal__techs">
      <div class="modal__techs-wrapper">
         <div class="search-wrapper" :class="{ 'search-wrapper--big' : isTechAddVisible }" >
           <div class="modal__techs-header">
             <div>
                   <input v-on:change="searchForTech($event.target.value)" class="modal__techs-search" type="text" placeholder="Поиск по фамилии"/>
                    <p v-if="noTechFound" class="input__message--invalid">Похожих тех. специалистов не найдено</p>
             </div>
              <button class="modal__techs--add-new"
                      v-on:click="addNewTech"
                      >
                  Добавить технического специалиста
               </button>
             <button class="modal__techs-close" v-on:click="closeTechsModal">Закрыть окно</button>
           </div>
            <div v-if="techsLoaded">
              <spinner></spinner>
            </div>
            <div v-else>
              <AddNewTech v-if="isTechAddVisible"></AddNewTech>
              <div class="modal__techs-table-row modal__techs-table-row--head">
                  <p class="id">id</p>
                  <p class="name">Фамилия</p>
                  <p class="surname">Имя</p>
                  <p class="patronymic">Отчество</p>
                  <!-- <p class="city">Город</p> -->
              </div>
               <div v-if="filteredTechs.length > 0">
                 <ModalTechsRow
                     v-for="tech in filteredTechs"
                     v-bind:key = "tech.id"
                     v-bind:TechRow="tech"
                     @click.native="addTechToArticle(tech)"
                 >
                  </ModalTechsRow>
               </div>
               <div v-else :key="updateList">
                 <ModalTechsRow
                   v-for="tech in techsList"
                   v-bind:key = "tech.id"
                   v-bind:TechRow="tech"
                   @click.native="addTechToArticle(tech)"
               >
               </ModalTechsRow>
               </div>
            </div>
         </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModalTechsRow from "@/components/ModalTech/ModalTechsRow";
import AddNewTech from "@/components/ModalTech/AddNewTech";
import Spinner from 'vue-simple-spinner'

export default {
  name: "ModalTechs",
  data() {
    return {
      techSearch: '',
      isTechAddVisible: false,
      filteredTechs: [],
      techsList: [],
      noTechFound: false,
      updateList: 1
    }
  },
  mounted() {
    this.$store.dispatch('modalTechs/getAllTechs');
  },
  components: {
    ModalTechsRow,
    Spinner,
    AddNewTech
  },
  computed: {
    ...mapState({
        techsListStore: state => state.modalTechs.techsList,
        techsLoaded: state => state.modalTechs. modalTechsLoaded
    })
  },
  watch: {
    techsListStore() {
      this.techsList = this.techsListStore
      this.techsList.length = this.techsListStore.length
    }
  },
  methods: {
    isAddTechRowVisible(newValue) {
      this.isTechAddVisible = newValue;
    },
    addNewTech() {
        this.isAddTechRowVisible(true)
        this.filteredTechs.length = 0
    },
    addTechToArticle(tech) {
        this.$store.dispatch('modalArticle/addTech', tech)
        this.closeTechsModal()
    },
    searchForTech(value) {
       this.filteredTechs = this.$store.getters['modalTechs/filterTech'](value)
       this.noTechFound = this.filteredTechs.length === 0;
    },
    closeTechsModal() {
      this.$store.dispatch('modalTechs/closeModal')
    },
    forceUpdate() {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>