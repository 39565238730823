<template>
          <button v-on:click="loadExcel"
                  class="article-registry__get-xlsx"
          >
            <spinner :size='9' v-if="isExcelLoaded"></spinner>
            <span v-else>Скачать реестр в формате xlsx</span>
          </button>
</template>

<script>

import Spinner from 'vue-simple-spinner'
import FileHandler from  "@/services/file.service"

export default {
  name: "loadExcelButton",
  props: ['loadType'],
  data() {
     return {
       isExcelLoaded: false
     }
  },

  components: {
      Spinner
  },
  methods: {
      loadExcel() {
        this.isExcelLoaded = true
        if (this.loadType === 1) {
            FileHandler.downloadArticleStat().then((response) => {
            FileHandler.saveReceivedfile(response, "export_articles", ".xlsx");
            this.isExcelLoaded = false
           })
        } else if(this.loadType === 2) {
           FileHandler.downloadActivitySet().then((response) => {
           FileHandler.saveReceivedfile(response, "export_activity", ".xlsx");
           this.isExcelLoaded = false
           });
        }else{
          FileHandler.downloadArticleMonitoringStat().then((response) => {
           FileHandler.saveReceivedfile(response, "export_articles_monitored", ".xlsx");
           this.isExcelLoaded = false
           });
        }
      }
  }
}
</script>

<style scoped>

</style>