import Vue from 'vue';
import Router from 'vue-router';
import Authorize from "@/components/Authorize";
import Register from "@/components/Authorize/Register";
import Login from "@/components/Authorize/Login";
import Home from "@/components/Home";
import ArticlesRegistry from "@/components/ArticlesRegistry";
import NotFoundPage from "@/components/NotFoundPage";
import Stastics from "./components/Stastics";
import Team from "./components/Team";
import AnalyticsDashboard from "@/components/SocialNetworking/AnalyticsDashboard";
const SocialNetwork = () => import('./components/SocialNetwork')
import statisticTableContent from "@/components/SocialNetworking/statisticTableComponents/statisticTableContent";
const ActivityRegistry = () => import("./components/ActivityRegistry");
import RememberPass from "@/components/Authorize/RememberPass";
import ResetPass from "@/components/Authorize/ResetPass";
import Verify from "./components/Authorize/Verify";
const Commentary = () => import ("./components/Commentary/Commentary");
const PostStatistic = () => import("@/components/PostsStatistic/PostStatistic")
const Rating = () => import("@/components/Rating")

import MonitoringArticlesRegistry from "@/components/MonitoringArticlesRegistry";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
   {
      path: '/auth',
      component: Authorize,
       children: [{
          path: '/login',
          name: 'login',
          component: Login
       },{
          path: '/register',
          name: 'register',
          component: Register
       }, {
           path: '/remember',
           name: 'remember',
           component: RememberPass
       }, {
          path: '/password/reset/verify/',
          name: 'resetPass',
          component: ResetPass
       }, {
          path: '/signup/verify/',
          name: 'verifyEmail',
          component: Verify
       }

       ]
    },
    {
      path: '/Home',
      name: 'Home',
      component: Home

    },
    {
      path: '/Articles',
      name: 'Articles',
      meta:{
        type:'ArticlesTable/getArticles',
        query:{page:1}
      },
      component: ArticlesRegistry

    },
    {
        path: '/Activity',
        name: 'Activity',
        component: ActivityRegistry,
        meta:{
          type:'ActivityTableStore/getActivity',
          query:{page:1}
        },
    },
    {
        path: '/Stastics',
        name: 'Stastics',
        component: Stastics
    }, {
        path: '/Commentary',
        name: 'Commentary',
        component: Commentary },
      {
        path: '/poststat',
        name: 'Poststat',
        component: PostStatistic
      },
      {
        path: '/Rating',
        name: 'Rating',
        component: Rating
      },
      {
        path: '/monitoring',
        name: 'MonitoringArticlesRegistry',
        component: MonitoringArticlesRegistry,
        meta:{
          type:'ArticlesTable/getMonitored',
          query:{page:1}
        }
      },
      {
        path: '/AnalyticsDashboard',
        name: 'AnalyticsDashboard',
        component: AnalyticsDashboard,
        children: [{
          path: 'visualize',
          name: 'visualize',
          component: SocialNetwork
       },{
          path: 'table',
          name: 'table',
          component: statisticTableContent,
       }]
    },
    {
        path: '/Team',
        name: 'Team',
        component: Team
    },

    {
        path: '*',
        name: 'notfound',
        component: NotFoundPage
    },
    {
          path: '/',
          name: 'Default',
          component: Home
    },

  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/remember', '/signup/verify/', '/password/reset/verify/', '/notfound'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
