<template>
    <div class="modal__add-article__content-block">
<!--        <input v-model="">-->
<!--        <input v="">-->
        
         <p class="modal__article--subtitle">Доп.баллы (блок видимый только главному редактору)</p>
        <!-- <div>  -->
         <div class="modal__add-article-additional">
           <label for="additionalPointsArticle">
             Доп.балл:
           </label>
           <!--  -->
           <input v-model="articleData.article_additional_points"
                  type="number"
                  id="additionalPointsArticle"
                  class="additional__points"
            >
           <label for="additionalPointsCommentary">
                 Доп.комментарий:
           </label>
           <input v-model="articleData.article_additional_commentary"
                  type="text"
                  id="additionalPointsCommentary"
                  
           >
         </div>
        <!-- </div> -->
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ArticleAdditional",
  computed: mapState ({
    articleData: state => state.modalArticle.article
    // articleAdditionalPoints: state => state.modalArticle.article.article_additional_points,
    // articleAdditionalCommentary: state => state.modalArticle.article.article_additional_commentary
  }),
}
</script>

<style scoped>

</style>