<template>
  <footer class='footer'>
    <div class="container">
      <div class="copyright">
      </div>
      <div class="credits">
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>