<template>
  <div class="modal__authors-table-row">
    <p class="id">{{author.id}}</p>
    <p class="name">{{author.name}}</p>
    <p class="surname">{{author.surname}}</p>
    <p class="patronymic">{{author.patronymic}}</p>
<!--    <p class="tb">{{author.tb}}</p>-->
    <p class="city">{{author.city}}</p>
  </div>

</template>

<script>
import Author from "@/models/author";


export default {
  name: "ModalAuthorsRow",
  props: ['AuthorRow'],
  data() {
    return {
      author: new Author(this.AuthorRow.id,
                         this.AuthorRow.name,
                         this.AuthorRow.surname,
                         this.AuthorRow.patronymic,
                         this.AuthorRow.city)
    }
  }
}
</script>

<style scoped>

</style>