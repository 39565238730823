<template>
    <div class="modal__add-article__content-block">
         <p class="modal__article--subtitle">Переписка по статье</p>
          <div v-if="!modalArticleForbidden"
              class="chat__header">
               <textarea v-model.lazy="$v.ArticleCommentary.$model"
                         rows="4"
                         cols="30"
                         class="chat__input"
               ></textarea>
              <button class="chat__save" v-on:click="saveArticleCommentary">Сохранить сообщение</button>

          </div>
           <div v-if="$v.ArticleCommentary.$error">
                   <p class="input__message--invalid input__message--center" v-if="!$v.ArticleCommentary.minLength || !$v.ArticleCommentary.required">Вы не написали комментарий</p>
           </div>

         <div class="chat__list">
              <div v-for="commentary in modalArticleData.commentary_list"
                   v-bind:key="commentary.id"
                  class="chat__element"
                  :class="{ 'chat__element--staff': commentary.is_staff}"
              >
                <p class="chat__element-date" v-html="formatDate(commentary.date_added)"></p>
                <p class="chat__element-author"

                   v-html="formatCommentaryAuthor(commentary.commentary_author, commentary.is_staff)">

                </p>
                <p class="chat__element-text" v-html="commentary.text"> </p>
              </div>
         </div>

    </div>
</template>

<script>
import {mapState} from "vuex";
import moment from 'moment';
import ArticalService from "@/services/articles.service"
import { minLength, required } from 'vuelidate/lib/validators'

export default {
  name: "ArticleChat",
  data() {
    return {
      ArticleCommentary: '',
    }
  },
  computed: mapState ({
    modalArticleLoaded: state => state.modalArticle.modalArticleLoaded,
    modalArticleData: state => state.modalArticle.article,
    modalArticleForbidden: state => state.modalArticle.modalAuthorInvalid
  }),
  validations: {
    ArticleCommentary: {
      required,
      minLength: minLength(5)
    }
  },

  methods: {
    formatDate(value) {
      return moment(new Date(value)).format('DD-MM-YYYY')
    },
    formatCommentaryAuthor(value, isStaff) {
      return isStaff ? value + ' (Рецензер)' : value + ' (Автор)'
    },
    saveArticleCommentary() {
      this.$v.ArticleCommentary.$touch();
      if (!this.$v.$invalid) {
        ArticalService.saveArticleCommentary(this.modalArticleData.id, this.ArticleCommentary).then(() => {
          this.$store.dispatch('modalArticle/getArticleData', this.modalArticleData.id)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>