<template>
  <div class="article__links--content-block">
    <div class="article__links-container">
      <div>
        <div>
          <p>{{ socialData.value }}:</p>
          <input type="text" v-model="fullSocialConten[socialData.id]" />
          <button @click="saveIdArticle">{{ textButton }}</button>
        </div>
        <div v-if="socialData.value != 'SD'" class="article__link-ref">
          <a
            v-if="modalArticleData.article_social_links != null"
            :href="link"
            >{{ this.link }}</a
          >
        </div>
        <div v-else class="article__link-ref" style="margin-left: 20%">
          {{
            modalArticleData.article_social_links &&
            modalArticleData.article_social_links.sberfriend_post_id
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticalService from '@/services/articles.service';
import { mapState } from 'vuex';
export default {
  name: 'article_links',
  props: ['socialData'],
  data() {
    return {
      textButton: 'Сохранить',
      socialContent: {
        nta_article_id: '',
      },
      fullSocialConten: {},
      link: '',
    };
  },
  created() {
    if (this.modalArticleData.article_social_links != null) {
      this.link =
        this.modalArticleData.article_social_links[this.socialData.id] != null
          ? this.socialData.link +
            this.modalArticleData.article_social_links[this.socialData.id]
          : null;
    }
  },
  watch: {
    modalArticleData() {
      if (
        this.modalArticleData.article_social_links[this.socialData.id] != null
      ) {
        this.link =
          this.socialData.link +
          this.modalArticleData.article_social_links[this.socialData.id];
      }
    },
  },
  methods: {
    saveIdArticle() {
      this.socialContent[this.socialData.id] =
        this.fullSocialConten[this.socialData.id].match(/\/(\d+)/)[1];

      this.socialContent.nta_article_id = this.modalArticleData.id;
      console.log(this.socialContent.nta_article_id);
      if (this.modalArticleData.article_social_links != null) {
        ArticalService.updateNtaSocialWebs(
          this.modalArticleData.article_social_links.id,
          this.socialContent
        ).then(() => {
          this.$store.dispatch(
            'modalArticle/getArticleData',
            this.modalArticleData.id
          );
        });
      } else {
        ArticalService.addNtaSocialWebs(this.socialContent).then(() => {
          this.$store.dispatch(
            'modalArticle/getArticleData',
            this.modalArticleData.id
          );
        });
      }
    },
  },
  computed: {
    ...mapState({
      modalArticleData: (state) => state.modalArticle.article,
    }),
  },
};
</script>
