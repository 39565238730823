export default new class {
    constructor() {
        this.state = {
             entityTypesList: []
        }
        this.mutations = {
             setEntityTypesList(state, data) {
                 state.entityTypesList = data
             }
        }
        // this.apiServices = {
        //     'articles': () => import ("@/services/activity.service"),
        //     'activity': () => import ("@/services/articles.service")
        // }
    }
}