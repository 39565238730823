<template>
<div>
    <router-view></router-view>
</div>
</template>

<script>
export default {
  name: "AnalyticsDashboard"
}
</script>

<style scoped>

</style>