<template>
  <div class="filter__selected-option" v-if="paramData !== null">
      <div v-for="item in paramData" :key="item.id">
          {{formattingSelectedValue(item.value)}}
      </div>
      <div class="filter__selected-option-close"
           v-on:click="removeFilteredParamFromQuery">
      </div>
  </div>
</template>

<script>
export default {
  name: "filterSelectedBlock",
  props: ['paramData'],
  methods: {
     removeFilteredParamFromQuery(){
        this.$parent.resetFilters();
     },
     formattingSelectedValue(item){
       let str = item.split(' (')[0]
       let arr = str.split(' ');
       arr.forEach(i=>(i.length >= 15)?str = i.substring(0, 12) +'-'+ i.substring(12):str)
       return str
     }
  }
}
</script>

<style scoped>

</style>