<template>
<div>
    <div class="statistic-social-networking__table-contentent">
        <div class="statistic-social-networking__table--id">{{item.id}}</div>
        <div class="statistic-social-networking__table--name" v-show="item.post_name !=null">{{item.post_name}}</div>
        <div class="statistic-social-networking__table--date" v-show="item.post_date !=null">{{dateFormat(item.post_date)}}</div>
        <div class="statistic-social-networking__table--link"><a :href="`${item.post_link}`">{{item.post_link}}</a></div>
        <div class="statistic-social-networking__table--stat">{{item.post_stat}}</div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
export default {
  name: "statisticRow",
    props: ['item'],
    methods:{
         dateFormat(datePublished){
            return moment(new Date(datePublished)).format("DD-MM-YYYY")
        }
    }
}
</script>

<style scoped>

</style>