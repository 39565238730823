<template>
    <div class="modal__add-article-publish-date">
        <label for="publishDate">
           Введите дату публикации
        </label>
        <input v-model="modalArticleData.date_published"
               type="date"
               id="publishDate">
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ArticlePublishDate",
  computed: mapState ({
    modalArticleData: state => state.modalArticle.article
  })
}
</script>

<style scoped>

</style>