<template>
    <div class="header" :class="{  'header--sidebar-active': sidebarActive }">
        <div class="header__container">
              <div class="header__sidebar"  ref="nav" >
                  <button @click = "toggleSidebar" class="header__sidebar-toggle-button">
                    <i class="header__sidebar-toggle-icon">
                      <font-awesome-icon icon="angle-double-right" v-if="!sidebarActive"/>
                      <font-awesome-icon icon="angle-double-left" v-else/>
                    </i>
                  </button>
                  <div class="header__sidebar-links">
                    <Sidebar :sidebarActive=sidebarActive  :surname=userData></Sidebar>
                  </div>
              </div>
          </div>
    </div>

</template>

<script>
import Sidebar from "@/components/Assets/Sidebar";
import { mapState } from 'vuex';

export default {
  name: "Header",
  data() {
    return {
      sidebarActive: false,
      collapsed: true,
    }},
  components: {
    Sidebar,
  },
  computed: {
    ...mapState ({
        userData: state => state.UserData.user,
      }),
  },
   methods:{
    toggleSidebar(){
      this.sidebarActive = !this.sidebarActive;
    }
  },
  created() {
    if(this.userData.name === null){
      this.$store.dispatch('UserData/loadUser')
    }
  }
}
</script>

<style scoped>


</style>
