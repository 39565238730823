<template>
  <div class="team_member">
    <div class="team_member-wrapper">
      <div class="team_member-photo">
        <img v-bind:src="worker.src" alt="">
      </div>
      <div class="team_member-info">
        <h4>{{worker.name}}</h4>
        <p>{{worker.role}}</p>
        <ul class="team_member-link">
          <li v-if=" worker.vk != '' "> <a v-bind:href="worker.vk"> <img src="icon/vk.png" alt=""> </a> </li>
          <!-- <li v-if=" worker.fb != '' "> <a v-bind:href="worker.fb"> <img src="icon/fb.png" alt=""> </a> </li> -->
          <li v-if=" worker.tw != '' "> <a v-bind:href="worker.tw"> <img src="icon/tw.png" alt=""> </a> </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "TeamMemberInfo",
  props: ['worker'],
}

</script>

<style scoped>

</style>
