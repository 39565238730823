<template>
    <div class="statistic__element">
        <div class="statistic__element-name">
            <p>{{name}}</p>
        </div>
        <div  class="statistic__element-value">
            <font-awesome-icon icon="chart-bar" />
            <h4>{{data}}</h4>
        </div>
    </div>
</template>

<script>


export default {
  name: "statSection",
  props: ['data', 'name'],
}
</script>

<style scoped>

</style>