import BaseRegistryState from "./BaseRegistryState";
import ArticleService from "@/services/articles.service"

const state = {
    ...BaseRegistryState.state,
}

const mutations = {
    ...BaseRegistryState.mutations,
}

const actions = {
    getArticleData({commit}) {
           commit('setDataLoadedFlagStatus', true)
           return ArticleService.getArticlesRegistryState().then(result => {
               commit('setLoadedData', result.data)
               commit('setDataLoadedFlagStatus', false)
           })
    },
}

const getters = {
    getReviewersStat: (state) => {
            return state.data['article_reviewers']
    },
    getStatusStat: (state) => {
            return state.data['article_statuses']
    },
}

export const articleRegistryState = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}