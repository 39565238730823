<template>
  <div class="container">
      <Header></Header>
    <div class='content'>
      <div class="container-header">
        <p>Команда</p>
      </div>
       <div class='team_member--wr'>
      <TeamMemberInfo
        v-for = "worker in site_workers"
        v-bind:key = "worker.id"
        v-bind:worker = "worker"
        ></TeamMemberInfo>
    </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import TeamMemberInfo from "@/components/Team/TeamMemberInfo";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    TeamMemberInfo,
  },
  computed: {
    site_workers() {
      return  [
                  { id:"1",name: "Ильина Людмила", role: "Главный редактор", src: "people/ilina.jpg",  vk: "", fb: "https://www.facebook.com/profile.php?id=100009086867668", tw: "",},
                  { id:"4",name: "Маслова Алёна", role: "Рецензер", src: "people/maslova.jpg",  vk: "https://vk.com/id2142026", fb: "https://www.facebook.com/profile.php?id=100019162835703", tw: "",},
                  { id:"5",name: "Выборнова Людмила", role: "Рецензер", src: "people/Vibornova.jpg",  vk: "https://vk.com/id658644370", fb: "https://www.facebook.com/profile.php?id=100055926395534", tw: "",},
                  { id:"7",name: "Алымова Елена", role: "Рецензер", src: "people/Alymova.jpg",  vk: "https://vk.com/id31393985", fb: "https://www.facebook.com/ea.alymova", tw: " https://mobile.twitter.com/AlymovaElAl",},
                  { id:"8",name: "Кашин Александр", role: "Рецензер", src: "people/Kashin.jpg",  vk: "https://vk.com/zloymors", fb: "https://www.facebook.com/aleksandr.kashin", tw: "https://twitter.com/zloymors",},
                  { id:"10",name: "Оганесян Евгения", role: "Рецензер", src: "people/Oganesyn.jpg",  vk: "", fb: "https://www.facebook.com/evgenia.oganesyan.9", tw: "",},
                ];
    }
  }
}
</script>

<style scoped>
</style>