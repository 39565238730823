<template>
  <div class="container">
     <Header></Header>
      <div class='content'>
        <div class="container-header">
          <p>МОНИТОРИНГ ПУБЛИКАЦИЙ</p>
        </div>
          <div class="article-registry">
               <div class="article-registry__header">
            </div>
      <MonitoringArticlesTable></MonitoringArticlesTable>
    </div>
        </div>
        <Footer></Footer>
        <modal_article
            v-if="modalVisible"
        ></modal_article>
        <div class="modal__background" v-if="modalVisible"></div>
  </div>

</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import modal_article from "@/components/ArticalsRegistry/modal_article";
import {mapState} from "vuex";
import MonitoringArticlesTable from "./MonitoringArticlesRegistry/MonitoringArticlesTable.vue"


export default {
  name: "ArticlesRegistry",
  data() {
    return {
      searchValue: '',
      searchProps: ''
    }
  },
  created() {
    this.$route.meta.query={page:1}
  },
  mounted() {
    this.$store.dispatch('ArticlesTypesStore/getTypesListFromAPI', 'articles');
  },
  computed: mapState({
    modalVisible: state => state.modalArticle.isArticleModalVisible
  }),
  components: {
    Header,
    Footer,
    // ArticleTable,
    modal_article,
    MonitoringArticlesTable,
    // loadExcelButton
  },
  methods: {
    createNewArticle: function () {
      this.$store.dispatch('modalArticle/createNewArticle')
    },
    downloadCheckList(){
      document.getElementById('linkExls').click()
      // var iframe = document.getElementById('invisible');
      // iframe.src = "@/src/assets/doc/1645766922375_Чек лист.xlsx";
    }

  }
}
</script>

<style scoped>
</style>