<template>
  <div class="home__content-block">
    <img src="home/1.png" alt="">
    <h2 class="home__content-block-title">Общие положения взаимодействия с реестром</h2>
    <div class="home__content-block-text-wrap">
      <p class="home__content-block-text">Каждой публикации размещённой в реестре будет присвоен один из 5 статусов( Новый, На доработку, Согласовано, Опубликовано, Отклонено).</p>
      <p class="home__content-block-text">Каждой публикации при добавлении в реестр автоматически будет присвоен статус "Новый".</p>
      <p class="home__content-block-text">Рецензер после расcмотрения публикации должен изменить статус в зависимости от своего решения по поводу размещённого материала.</p>
      <p class="home__content-block-text">Если к материалам публикации нет замечаний, ей будет присвоен статус "Согласовано", далее публикация будет размещена на самом портале NTA и статус будет "Опубликовано".</p>
      <p class="home__content-block-text">Если к публикации есть замечания, то статус изменится на "На доработку" и в комментариях будут написаны замечания рецензера.</p>
      <p class="home__content-block-text">Если публикация не соответствует критериям портала NTA, то статус изменится на "Отклонено".</p>
    </div>
  </div>
</template>

<script>


export default {
  name: "Infographics",
}

</script>

<style scoped>

</style>
