<template>
  <div class="modal modal__article">
    <div class="modal__container">
      <div v-if="modalArticleLoaded">
        <p style="text-align: center" v-if="modalArticleSaving">
          Идет сохранение статьи
        </p>
        <spinner></spinner>
      </div>
      <div v-else>
        <!--          <div v-if="modalArticleForbidden" class="modal__article-forbidden">-->
        <!--            <p class="modal__article-forbidden-text">Извините, у вас нет права на редактирование данной статьи</p>-->
        <!--             <button v-on:click="closeModal" class="cancellation" name="submit" value="Отмена">Закрыть</button>-->
        <!--          </div>-->

        <!--          <h4 v-if='newArticle' class="modal__article&#45;&#45;title">Добавление новой статьи</h4>-->
        <p v-if="modalArticleForbidden">
          Вы не являетесь автором данной статьи
        </p>
        <p v-if="modalArticleForbidden">Вам доступно лишь скачивание статьи</p>
        <form v-on:submit.prevent>
          <div class="article-info">
            <div>
              <div>
                <p class="modal__article--subtitle">
                  ТБ (*необязательный параметр для внешних авторов)
                </p>
                <div>
                  <select
                    class="modal__article--tb"
                    name=""
                    v-model="modalArticleData.tb"
                    :disabled="modalArticleForbidden"
                  >
                    <option>ББ</option>
                    <option>УБ</option>
                    <option>ВВБ</option>
                    <option>СЗБ</option>
                    <option>СРБ</option>
                    <option>ПБ</option>
                    <option>СибБ</option>
                    <option>ЦЧБ</option>
                    <option>ЮЗБ</option>
                    <option>ДВБ</option>
                    <option>МБ</option>
                  </select>
                </div>
              </div>
              <div>
                <p class="modal__article--subtitle">
                  Статья
                  <span v-if="modalArticleData.id > 0">
                    {{ modalArticleData.id }}</span
                  >
                </p>

                <div class="article-info__heading">
                  <!-- <div> -->
                  <label for="">Рубрика:</label>
                  <!-- </div>
                <div> -->
                  <select
                    v-model.lazy="$v.modalArticleData.article_heading.id.$model"
                    :class="{
                      'input--invalid':
                        !$v.modalArticleData.article_heading.id.minLength,
                    }"
                    name=""
                    :disabled="modalArticleForbidden"
                  >
                    <option hidden disabled selected value></option>
                    <option
                      v-for="item in articleTypesList"
                      :key="item.id"
                      :value="item.id"
                      :disabled="!item.is_active"
                    >
                      {{ item.category_name }}
                    </option>
                  </select>
                  <!-- </div> -->
                </div>
                <div v-if="$v.modalArticleData.article_heading.id.$error">
                  <p
                    class="input__message--invalid"
                    v-if="!$v.modalArticleData.article_heading.id.required"
                  >
                    Вы не выбрали рубрику
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div class="article-info__name">
                <label>Название статьи: (не менее 10 символов)</label>
                <!--                <input v-model="modalArticleData.article_name" class="" type="text">-->
                <input
                  v-model.lazy="$v.modalArticleData.article_name.$model"
                  class=""
                  type="text"
                  :class="{
                    'input--invalid':
                      !$v.modalArticleData.article_name.minLength,
                  }"
                  :disabled="modalArticleForbidden"
                />
                <!--                <p class="input__message&#45;&#45;invalid" v-if="$v.modalArticleData.article_name.required">Вы не ввели название статьи</p>-->
              </div>
            </div>

            <div>
              <div>
                <div v-if="$v.modalArticleData.article_name.$error">
                  <p
                    class="input__message--invalid"
                    v-if="
                      !$v.modalArticleData.article_name.minLength ||
                      !$v.modalArticleData.article_name.required
                    "
                  >
                    Введите название статьи
                  </p>
                </div>
                <p class="modal__article--subtitle">
                  Автор(ы) статьи, Фамилия Имя Город
                </p>
                <div
                  v-for="author in modalArticleAuthor"
                  v-bind:key="author.id"
                  class="modal__add-article-authors"
                >
                  <p
                    v-html="
                      author.name + ' ' + author.surname + ',г. ' + author.city
                    "
                    class=""
                  ></p>
                  <button
                    v-on:click="removeAuthor(author.id)"
                    class="modal__add-article-authors-delete"
                    :disabled="modalArticleForbidden"
                  >
                    Удалить автора
                  </button>
                </div>
                <p class="input__message--invalid" v-if="maxAuthorsTrigger">
                  Превышено максимальное количество авторов
                </p>
                <button
                  @click="addAuthor"
                  class="modal__add-article-authors-add"
                  :disabled="modalArticleForbidden"
                >
                  Добавить автора
                </button>
                <div v-if="$v.modalArticleAuthor.$error">
                  <p
                    class="input__message--invalid"
                    v-if="
                      !$v.modalArticleAuthor.minLength ||
                      !$v.modalArticleAuthor.required
                    "
                  >
                    Вы не добавили автора
                  </p>
                </div>
                <div class="modal__add-article-is_ublication">
                  <input
                    type="checkbox"
                    id="isPublication"
                    name="isPublication"
                    checked
                    v-model="modalArticleData.is_publication_author"
                    style="width: 3%"
                    :disabled="modalArticleData.id !== 'null'"
                  />
                  <p>
                    Согласен на публикацию ФИО при размещении поста на VC/Habr
                  </p>
                </div>
              </div>

              <div>
                <p class="modal__article--subtitle">Статус статьи</p>
                <div class="article-info__heading">
                  <p
                    v-if="!modalArticleData.is_staff"
                    v-html="modalArticleData.status"
                  ></p>
                  <select
                    v-if="modalArticleData.is_staff"
                    v-model="modalArticleData.status"
                    v-on:change="isArticlePublished($event.target.value)"
                    :class="{
                      disabled: modalArticleData.tb_responsible_name == null,
                    }"
                    name=""
                  >
                    <option>Новый</option>
                    <option>Принято в работу</option>
                    <option>На доработку</option>
                    <option>Согласовано</option>
                    <option>Опубликовано</option>
                    <option>Отклонено</option>
                  </select>
                  <ArticlePublishDate
                    v-if="isPublisheDateVisible"
                  ></ArticlePublishDate>

                  <article-plagiat
                    v-if="modalArticleData.plagiat_percent"
                  ></article-plagiat>
                </div>
              </div>
            </div>
            <div>
              <div class="modal__article--text-section">
                <p class="modal__article--subtitle">Тэги</p>
                <label class="modal__article--tagLabel">
                  Tools
                  <input
                    placeholder="Введите tools через запятую без пробелов"
                    type="text"
                    v-model="modalArticleData.tools_tag"
                  />
                </label>
                <label class="modal__article--tagLabel">
                  Rules
                  <input
                    placeholder="Введите rules через запятую без пробелов"
                    type="text"
                    v-model="modalArticleData.rule_tag"
                  />
                </label>
                <label class="modal__article--tagLabel">
                  Libs
                  <input
                    placeholder="Введите libs через запятую без пробелов"
                    type="text"
                    v-model="modalArticleData.libs_tag"
                  />
                </label>
              </div>
            </div>
            <div>
              <div class="modal__article--text-section">
                <p class="modal__article--subtitle">
                  Текст статьи (текстовый файл в формате docx)
                </p>
                <div>
                  <span
                    >Внимание! Загрузка статей не работает с ноутбуков SIGMA и
                    ВАРМов, учитывайте это при размещении<br
                  /></span>
                  <input
                    v-on:change="setFile($event)"
                    type="file"
                    accept=".doc,.docx"
                    class="modal__add-article-file"
                    :disabled="modalArticleForbidden"
                  />
                </div>
                <div>
                  <button
                    v-on:click="downloadFile"
                    class="modal__add-article__download-file"
                  >
                    <!-- v-if="modalArticleData.article_link === 'article'"  Возможность скачивать только неопубликованные статьи-->
                    Скачать статью
                  </button>
                </div>
                <div>
                  <a
                    v-if="
                      modalArticleData.article_link !== 'article' &&
                      modalArticleData.article_link !== undefined
                    "
                    :href="modalArticleData.article_link"
                    target="_blank"
                  >
                    Ссылка на статью на сайте NTA
                  </a>
                </div>
                <div v-if="!modalArticleData.article_link">
                  <div v-if="$v.fileData.$error">
                    <p
                      class="input__message--invalid"
                      v-if="!$v.fileData.required"
                    >
                      Вы не загрузили статью
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p class="modal__article--subtitle">Дополнительно</p>

                <div class="article-info">
                  <div class="article-info__individual-comment">
                    <label
                      >Индивидуальный комментарий (от автора рецензерам)</label
                    >
                    <input
                      v-model="modalArticleData.article_commentary"
                      class="modal-authentication__wrapper__user-password"
                      type="text"
                      :disabled="modalArticleForbidden"
                    />
                  </div>
                </div>
                <!-- <div> -->

                <!-- </div> -->
              </div>
            </div>
            <div>
              <!-- v-if="modalArticleData.is_staff"  -->
              <div>
                <p class="modal__article--subtitle">
                  Ссылки на социальные сети
                </p>
                <div class="modal__article--is-habr">
                  <input
                    type="checkbox"
                    id="isHabr"
                    name="isHabr"
                    class="modal__article--is-habr"
                    v-model="modalArticleData.is_habr_vc"
                  />
                  <p>подходит для Habr/VC</p>
                </div>
                <ArticleLinks
                  v-for="item in this.socialList"
                  :key="item.id"
                  :socialData="item"
                ></ArticleLinks>
              </div>

              <ArticleAdditional
                v-if="modalArticleData.is_base_bank"
              ></ArticleAdditional>
            </div>
          </div>

          <div
            class="modal__add-article__wrapper modal__add-article__wrapper--space-between"
          >
            <button
              v-on:click="submitArticleForm"
              :disabled="modalArticleForbidden"
              class="article_save"
            >
              Сохранить
            </button>
            <!-- {{this.modalArticleData.is_habr_vc}} -->
            <div>
              <div
                v-if="modalArticleData.tb_responsible"
                class="modal__add-article__redactor-name"
              >
                Проверяющий статьи: <br />
                <span v-if="modalArticleData.tb_responsible_name">{{
                  modalArticleData.tb_responsible_name + ', '
                }}</span>
                <span v-if="modalArticleData.tb_responsible">
                  {{ modalArticleData.tb_responsible }}
                </span>
              </div>

              <ArticleReviewer
                v-if="modalArticleData.is_staff === 'true'"
              ></ArticleReviewer>
            </div>
            <div v-if="modalArticleData.is_staff === 'true'">
              <div
                class="modal__add-article__redactor-name"
                v-for="tech in modalArticleTech"
                v-bind:key="tech.id"
              >
                Тех. специалист статьи: <br />
                <span
                  v-html="
                    tech.name + ' ' + tech.surname + ' ' + tech.patronymic
                  "
                  class=""
                ></span>
              </div>
              <br />
              <div
                v-for="tech in modalArticleTech"
                v-bind:key="tech.id"
                class="modal__add-tech-article"
              >
                <button
                  v-on:click="removeTech(tech.id)"
                  class="modal__add-tech-article-delete"
                  :disabled="modalArticleForbidden"
                >
                  Удалить тех. рецензера
                </button>
              </div>
              <p class="input__message--invalid" v-if="maxTechsTrigger">
                Превышено максимальное количество технических рецензеров
              </p>
              <button
                @click="addTech"
                class="modal__add-article__set-redactor"
                :disabled="modalArticleForbidden"
              >
                Добавить тех. специалиста
              </button>
            </div>

            <button
              v-on:click="closeModal"
              class="cancellation"
              name="submit"
              value="Отмена"
            >
              Закрыть
            </button>
          </div>

          <ArticleChat v-if="modalArticleData.id !== 'null'"></ArticleChat>
        </form>
      </div>
    </div>

    <ModalAuthors v-if="modalAuthorsVisible"></ModalAuthors>
    <ModalTechs v-if="modalTechsVisible"></ModalTechs>
  </div>
</template>

<script>
import ArticalService from '@/services/articles.service';
import ModalAuthors from '@/components/ModalAuthor/ModalAuthors';
import ModalTechs from '@/components/ModalTech/ModalTechs';
import { minLength, required } from 'vuelidate/lib/validators';
import Spinner from 'vue-simple-spinner';
import { mapState } from 'vuex';
import ArticlePlagiat from '@/components/ArticalsRegistry/modalArticle/ArticlePlagiat';
import ArticleChat from '@/components/ArticalsRegistry/modalArticle/ArticleChat';
import ArticleAdditional from '@/components/ArticalsRegistry/modalArticle/ArticleAdditional';
import ArticlePublishDate from './modalArticle/ArticlePublishDate';
import ArticleReviewer from './modalArticle/ArticleReviewer';
import ArticleLinks from '@/components/ArticalsRegistry/modalArticle/ArticleLinks';

export default {
  name: 'modal_article',
  props: ['articleID'],
  data() {
    return {
      checkedIsHabrVC: false,
      maxAuthorsTrigger: false,
      maxTechsTrigger: false,
      fileData: null,
      modalArticleSaving: false,
      isPublisheDateVisible: false,
      socialList: [
        {
          id: 'sberfriend_post_id',
          value: 'SD',
          link: 'https://sberfriend.sbrf.ru/sberfriend/#/media/article/',
        },
        { id: 'vc_post_id', value: 'VС', link: 'https://vc.ru/newtechaudit/' },
        {
          id: 'habr_post_id',
          value: 'Habr',
          link: 'https://habr.com/ru/post/',
        },
      ],
      checkedIsPublicationFIO: false,
    };
  },
  watch: {
    modalArticleData() {
      this.checkedIsHabrVC = this.modalArticleData.is_habr_vc;
      this.checkedIsPublicationFIO =
        this.modalArticleData.is_publication_author;
    },
  },
  mounted() {
    setTimeout(() => {
      console.log(this.modalArticleData);
    }, 1000);
  },
  computed: {
    ...mapState({
      modalArticleLoaded: (state) => state.modalArticle.modalArticleLoaded,
      modalArticleData: (state) => state.modalArticle.article,
      modalArticleAuthor: (state) => state.modalArticle.authors,
      modalArticleTech: (state) => state.modalArticle.techspecs,
      modalArticleForbidden: (state) => state.modalArticle.modalAuthorInvalid,
      modalAuthorsVisible: (state) => state.modalAuthors.modalVisible,
      modalTechsVisible: (state) => state.modalTechs.modalVisible,
      articleTypesList: (state) => state.ArticlesTypesStore.entityTypesList,
      articleData: (state) => state.ArticlesTable.articles,
    }),
    //    this.checkedIsHabrVC = this.modalArticleData.is_habr_vc;
    // console.log('sd',this.modalArticleData.is_habr_vc)
  },
  components: {
    Spinner,
    ModalAuthors,
    ModalTechs,
    ArticlePlagiat,
    ArticleChat,
    ArticleAdditional,
    ArticlePublishDate,
    ArticleReviewer,
    ArticleLinks,
  },
  validations() {
    if (this.modalArticleData.id === 'null') {
      return {
        fileData: {
          required,
        },
        modalArticleData: {
          article_name: {
            required,
            minLength: minLength(10),
          },
          article_heading: {
            id: {
              required,
              minLength: minLength(1),
            },
          },
        },
        modalArticleAuthor: {
          required,
          minLength: minLength(1),
        },
      };
    } else {
      return {
        modalArticleData: {
          article_name: {
            required,
            minLength: minLength(10),
          },
          article_heading: {
            id: {
              required,
              minLength: minLength(1),
            },
          },
        },
        modalArticleAuthor: {
          required,
          minLength: minLength(1),
        },
      };
    }
  },
  destroyed() {
    this.closeModal();
  },

  methods: {
    isArticlePublished(value) {
      this.isPublisheDateVisible = false;
      if (value === 'Опубликовано') {
        this.isPublisheDateVisible = true;
      }
    },
    setFile(event) {
      this.fileData = event.target.files[0];
    },
    downloadFile() {
      ArticalService.downloadArticleFile(this.modalArticleData.id).then(
        (response) => {
          let blob = new Blob([response.data]);
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob); //create the download url
          downloadElement.href = href;
          downloadElement.download = this.modalArticleData.id + '.docx'; //the name of the downloaded file
          document.body.appendChild(downloadElement);
          downloadElement.click(); //click to file
          document.body.removeChild(downloadElement); //remove the element
          window.URL.revokeObjectURL(href);
        }
      );
    },
    addTech() {
      if (this.modalArticleTech.length < 1) {
        this.$store.dispatch('modalTechs/showModal', true);
      } else {
        this.maxTechsTrigger = true;
      }
    },
    addAuthor() {
      if (this.modalArticleAuthor.length <= 2) {
        this.$store.dispatch('modalAuthors/showModal', true);
      } else {
        this.maxAuthorsTrigger = true;
      }
    },
    removeAuthor(id) {
      this.maxAuthorsTrigger = false;
      this.$store.dispatch('modalArticle/removeArticleAuthor', id);
    },
    removeTech(id) {
      this.maxTechsTrigger = false;
      this.$store.dispatch('modalArticle/removeArticleTech', id);
    },
    closeModal() {
      this.$store.dispatch(
        'modalArticle/closeArticleModal',
        this.$route.meta.query
      );
    },
    submitArticleForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modalArticleSaving = true;
        this.$store
          .dispatch('modalArticle/saveArticleData', {
            data: this.modalArticleData,
            file: this.fileData,
          })
          .then(() => {
            this.modalArticleSaving = false;
            this.closeModal();
          });
      }
    },
  },
};
</script>

<style scoped></style>
