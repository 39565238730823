import axios from "axios";
import config from '@/services/config.service'

const API_URL = config['API_LOCATION'] + 'api/articles/tech-spec/list'

class TechService {
    async createNewTech(data) {
       return await axios.post(API_URL, {
           name: data.name,
           surname: data.surname,
           patronymic: data.patronymic,
        //    city: data.city
       })
    }

    async updateTech(techId) {
        return await axios.put(API_URL + 'update/' + techId)
    }

    async getAllTech() {
        return await axios.get(API_URL)
    }
}

export default new TechService();