import Vue from 'vue'
import Vuelidate from 'vuelidate'


import App from './App.vue'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { router } from './router';

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));


// Vue.filter('setFirstLetterUpper', function (value) {
//   if (!value) return ''
//   value = value.toString()
//   return value.charAt(0).toUpperCase() + value.slice(1)
// })
//
// Vue.directive('setFirstLetterUpper', {
//   twoWay: true, // this transformation applies back to the vm
//   bind: function () {
//     this.handler = function () {
//       this.set(this.el.value.charAt(0).toUpperCase() + this.el.value.slice(1));
//     }.bind(this);
//     this.el.addEventListener('input', this.handler);
//   },
//   unbind: function () {
//     this.el.removeEventListener('input', this.handler);
//   }
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
