import ActivityService from "@/services/activity.service"
import Activity from "@/models/activity";

export const modalActivity = {
    namespaced: true,
    state: () => ({
        activity: [],
        modalActivityLoaded: false,
        isActivityModalVisible: false,
        modalActivityInvalid: false
    }),

    mutations: {
        addActivityAuthor(state, author) {
            state.activity.author.push(author)
        },
        getActivityData(state, activityLoadedData) {
            // state.activity = new Activity(activityLoadedData)
            state.activity =activityLoadedData
        },
        loadingModalActivity(state, newLoadingStatus) {
            state.modalActivityLoaded = newLoadingStatus
        },
        isModalActivityVisible(state, newStatus) {
            state.isActivityModalVisible = newStatus
            document.body.classList.add('modal--overflow-hidden')
        },
        modalActivityInvalid(state, activityLoadedData) {
            state.modalActivityInvalid = true
            state.activity = activityLoadedData
        },
        resetModalState(state) {
            state.activity = [];
            state.modalActivityLoaded = false;
            state.isActivityModalVisible = false;
            state.modalActivityInvalid = false;
            document.body.classList.remove('modal--overflow-hidden');
        },
        createNewActivity(state) {
            state.activity = new Activity({id:'null'})
        }

    },
  actions: {

    getActivityData ({ commit }, id) {
        commit('isModalActivityVisible', true);
        commit('loadingModalActivity', true)
         return ActivityService.getSingleActivity(id)
          .then(result => {
            commit('loadingModalActivity', false)
            commit('getActivityData', result.data)
          })
          
          .catch(error => {
              commit('loadingModalActivity', false)
              commit('modalActivityInvalid', error.response.data)
          })
    },
    createNewActivity({commit}) {
        commit('isModalActivityVisible', true);
        commit('createNewActivity');
    },
    closeActivityModal({commit, dispatch}, data) {
        commit('resetModalState')
        dispatch('ActivityTableStore/getActivity', data, {root: true})
    },
    saveActivityData({commit}, data) {
        commit('loadingModalActivity', true)
         return ActivityService.saveActivityForm(data)
          .then(() => {
            commit('loadingModalActivity', false)
          })
          .catch(error => console.log(error))
    },
      deleteActivityData({commit}, id) {
        commit('loadingModalActivity', true)
          return ActivityService.deleteActivityForm(id)
              .then( () => {
                  commit('loadingModalActivity', false)
              })
              .catch( error => console.log(error) )
      }
  },

  getters: {
      modalActivityData(state) {
          return state.activity
      },
      modalActivityLoaded(state){
          return state.modalActivityLoaded
      }
  }
}
