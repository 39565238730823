<template>
  <div class="modal__authors">
      <div class="modal__authors-wrapper">
         <div class="search-wrapper" :class="{ 'search-wrapper--big' : isAuthorAddVisible }" >
           <div class="modal__authors-header">
             <div>
                   <input v-on:change="searchForAuthor($event.target.value)" class="modal__authors-search" type="text" placeholder="Поиск автора по фамилии"/>
                    <p v-if="noAuthorFound" class="input__message--invalid">Похожих авторов не найдено</p>
             </div>
              <button class="modal__authors--add-new"
                      v-on:click="addNewAuthor"
                      >
                  Добавить автора
               </button>
             <button class="modal__authors-close" v-on:click="closeAuthorsModal">Закрыть окно</button>
           </div>
            <div v-if="authorsLoaded">
              <spinner></spinner>
            </div>
            <div v-else>
              <AddNewAuthor v-if="isAuthorAddVisible"></AddNewAuthor>
              <div class="modal__authors-table-row modal__authors-table-row--head">
                  <p class="id">id</p>
                  <p class="name">Фамилия</p>
                  <p class="surname">Имя</p>
                  <p class="patronymic">Отчество</p>
                  <p class="city">Город</p>
              </div>
               <div v-if="filteredAuthors.length > 0">
                 <ModalAuthorsRow
                     v-for="author in filteredAuthors"
                     v-bind:key = "author.id"
                     v-bind:AuthorRow="author"
                     @click.native="addAuthorToArticle(author)"
                 >
                  </ModalAuthorsRow>
               </div>
               <div v-else :key="updateList">
                 <ModalAuthorsRow
                   v-for="author in authorsList"
                   v-bind:key = "author.id"
                   v-bind:AuthorRow="author"
                   @click.native="addAuthorToArticle(author)"
               >
               </ModalAuthorsRow>
               </div>
            </div>
         </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModalAuthorsRow from "@/components/ModalAuthor/ModalAuthorsRow";
import AddNewAuthor from "@/components/ModalAuthor/AddNewAuthor";
import Spinner from 'vue-simple-spinner'

export default {
  name: "ModalAuthors",
  data() {
    return {
      authorSearch: '',
      isAuthorAddVisible: false,
      filteredAuthors: [],
      authorsList: [],
      noAuthorFound: false,
      updateList: 1
    }
  },
  mounted() {
    this.$store.dispatch('modalAuthors/getAllAuthors');
  },
  components: {
    ModalAuthorsRow,
    Spinner,
    AddNewAuthor
  },
  computed: {
    ...mapState({
        authorsListStore: state => state.modalAuthors.authorsList,
        authorsLoaded: state => state.modalAuthors. modalAuthorsLoaded
    })
  },
  watch: {
    authorsListStore() {
      this.authorsList = this.authorsListStore
      this.authorsList.length = this.authorsListStore.length
    }
  },
  methods: {
    isAddAuthorRowVisible(newValue) {
      this.isAuthorAddVisible = newValue;
    },
    addNewAuthor() {
        this.isAddAuthorRowVisible(true)
        this.filteredAuthors.length = 0
    },
    addAuthorToArticle(author) {
        this.$store.dispatch('modalArticle/addAuthor', author)
        this.closeAuthorsModal()
    },
    searchForAuthor(value) {
       this.filteredAuthors = this.$store.getters['modalAuthors/filterAuthor'](value)
       this.noAuthorFound = this.filteredAuthors.length === 0;
    },
    closeAuthorsModal() {
      this.$store.dispatch('modalAuthors/closeModal')
    },
    forceUpdate() {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>