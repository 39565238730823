import BaseAnalyticsModule from "./analytics/BaseAnalyticsModule";
import SocialNetworkingService from "@/services/social-networking.service";

const state = {
    ...BaseAnalyticsModule.state,
    data:[]
}


const actions = {
    ...BaseAnalyticsModule.actions,
    getStatisticData({commit, state}) {
        commit('loadingStatisticStatus', true)
            let statisticTypes = {
                'dynamic': () => SocialNetworkingService.getArticleDynamicStat(state.requestQuery),
                'common': () => SocialNetworkingService.getArticleCommonStat(state.requestQuery),
            }
            statisticTypes[state.requestAggregationType]().then(response => {
                commit('updateStatisticStoreData', response.data)
                commit('loadingStatisticStatus', false)
            })
    }
}

const mutations = {
    ...BaseAnalyticsModule.mutations,
    updateStatisticStoreData(state, data){
            state.data = data
    }
}

const getters = {
    getRequestAggregationType:(state) => {
        return state.requestAggregationType
    },
    getRequestAggregationName:(state) => {
        return state.requestQuery.name
    },
    getRequestAggregationIndicate:(state) => {
        return state.requestQuery.statistic_type
    },
}
export const analyticsTable = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}