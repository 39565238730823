<template>
   <div class="statistic">
      <Header></Header>
      <div class="content content--extendede">
        <div class="container-header">
          <p>Аналитика социальных сетей</p>
        </div>
        <div class="statistic__container">
          <div><statisticHeader :componentName = $options.name></statisticHeader></div>
          <div v-if="statisticDataLoaded">
          <spinner></spinner>
              </div>
          <div class="" v-else>
              <div class="statistic-social-networking__table-barchart-filter">
                  <statistic-line-chart-period-filter></statistic-line-chart-period-filter>
              </div>
              <!-- ddfs{{this.data}} -->
              <div class="statistic-social-networking__table-contentent statistic-social-networking__table--header">
                <div class="statistic-social-networking__table--id">ID</div>
                <div class="statistic-social-networking__table--name" v-show="checkingNameArticle">Назввание статьи</div>
                <div class="statistic-social-networking__table--date" >Дата</div>
                <div class="statistic-social-networking__table--link">Ссылка</div>
                <div class="statistic-social-networking__table--stat">Статистика</div>
              </div>
              <div>
            <statisticRow v-for="item, index in data" :key="index" :item = item></statisticRow>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import statisticHeader from "@/components/SocialNetworking/statisticHeader";
import statisticLineChartPeriodFilter from "@/components/SocialNetworking/statisticLineChartPeriodFilter";
import statisticRow from "@/components/SocialNetworking/statisticTableComponents/statisticRow"
import Spinner from 'vue-simple-spinner'
import {mapState} from "vuex";
import {mapGetters} from "vuex";


export default {
  name: "statisticTableContent",
  computed:{
    ...mapState ({
      data: state => state.analyticsTable.data,
      statisticDataLoaded: state => state.analyticsTable.statisticDataLoaded,
      checkName: state => state.analyticsTable.check_name,
  }),
  ...mapGetters('analyticsTable',['checkingNameArticle','checkingDateArticle'])
  },
  components: {
      Header,
      Footer,
      statisticRow,
      statisticLineChartPeriodFilter,
      statisticHeader,
      Spinner
  },
  mounted() {
     this.$store.dispatch('analyticsTable/getStatisticData')
  },
     
}
</script>

<style scoped>

</style>