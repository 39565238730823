<template>
  <div>
      <div class="article-registry__table">
        <div class="article-monitoring__xls">
          <load-excel-button v-bind:load-type="3"></load-excel-button>
        </div>
         <div class="article-registry__filters">
              <MonitoringArticlesFilters title="Фильтры"/>
          </div>
        <div class="article-registry__table-content" >
          
           <div class="article-registry__table-row article-registry__table-row--head" style="padding-top:5px;padding-bottom:5px;">
            <div class="article-registry__table-sell--id">№</div>
            <div class="article-registry__table-sell--id">ТБ</div>
            <div class="article-registry__table-sell--medium">ФИО автора</div>
            <div class="article-registry__table-sell--heading">Название</div>
            <div class="article-registry__table-sell--heading">Дата регистрации статьи в реестре</div>
            <div class="article-registry__table-sell--heading">% уникальности перед публикацией</div>
            <div class="article-registry__table-sell--heading">Дата первой публикации</div>
            <div class="article-registry__table-sell--heading">Ссылка на площадку первичной публикации</div>
            <div class="article-registry__table-sell--heading">Дата проверки</div>
            <div class="article-registry__table-sell--heading">№ проверки</div>
            <div class="article-registry__table-sell--heading">Ссылки сайтов по проверке</div>
            <div class="article-registry__table-sell--heading">% заимствования статьи</div>
        </div>
            
         <MonitoringArticlesColumn 
           v-for="(article,index) in articleData.results"
           v-bind:key = "index"
           v-bind:ArticleColumn ="article"
         ></MonitoringArticlesColumn>
        </div>
        <div class="article-registry__page-control">
          
               <button v-on:click="loadPreviousPage"
                     v-if="isPreviousButtonVisible" class="article-registry__page-control-previous">Предыдущая страница</button>
               <button v-on:click="loadNextPage2()"
                       v-if="isNextButtonVisible"
                       class="article-registry__page-control-next">Следующая страница</button>
        </div>
      </div>
  </div>
</template>
<script>
import loadExcelButton from "@/components/Assets/Buttons/loadExcelButton";
import MonitoringArticlesColumn from "./MonitoringArticlesColumn";
import MonitoringArticlesFilters from "./MonitoringArticlesFilters/MonitoringArticlesFilters";
import { mapState } from 'vuex';
export default {
  name: "ArticleTable",
  data() {
    return {
      page: this.$route.query.page
    }
  },
  components: {
    MonitoringArticlesColumn,
    MonitoringArticlesFilters,
    loadExcelButton
    
  },
  mounted() {
    this.$store.dispatch('ArticlesTable/getMonitored', this.$route.query.page);
  },
  computed:mapState ({
    articleDataLoaded: state => state.ArticlesTable.articleDataLoaded,
    articleData: state => state.ArticlesTable.monitoredArticles,
       isPreviousButtonVisible: state => state.ArticlesTable.articlePreviousButton,
    isNextButtonVisible: state => state.ArticlesTable.articleNextButton,
  
  }),

  methods: {
    loadNextPage2() {
        window.scrollTo(0,0)
        let newPageNumber = parseInt(this.$route.meta.query.page) + 1
        this.filterContentFromAPI({page: newPageNumber})
    },
    loadPreviousPage() {
        let newPageNumber = parseInt(this.$route.meta.query.page)-1
        this.filterContentFromAPI({page: newPageNumber})
    },
    filterContentFromAPI(routeObject) {
      this.$route.meta.query={...this.$route.meta.query, ...routeObject}
      this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
  }
}
</script>

<style scoped>
</style>
