<template>
  <div class="container">
        <Header></Header>
      <div class="content">
        <div class="container-header">
          <p style="font-size:30px; margin-left:12px;">Сервис NTA</p>
        </div>
        <div class="home">
            <InfoBlockWorkRules />
            <Infographics />
            <Events />
        </div>
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import InfoBlockWorkRules from "@/components/Home/InfoBlockWorkRules";
import Infographics from "@/components/Home/Infographics";
import Events from "@/components/Home/Events";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    InfoBlockWorkRules,
    Infographics,
    Events
  }
}
</script>

<style scoped>

</style>
