export default new class {
    constructor() {
        this.state = {
            data: [],
            dataLoaded: false,
        }
        this.mutations = {
            setDataLoadedFlagStatus (state, newLoadingeStatisticsStatus) {
                state.dataLoaded = newLoadingeStatisticsStatus
            },
            setLoadedData(state, data){
                state.data = data
            }
        }
    }
}