import BaseRegistryEntityType from "../registry/BaseRegistryEntityType"
import ArticalService from "@/services/articles.service"

// const apiServices = {
//     ...BaseRegistryEntityType.apiServices
// }

const InitialState = {
    ...BaseRegistryEntityType.state
}

export default {
    namespaced: true,
    state: () => (InitialState),
    mutations: {
       ...BaseRegistryEntityType.mutations,
    },
    actions: {
        getTypesListFromAPI({ commit }) {
            return ArticalService.getTypes()
                .then(result => {
                    commit('setEntityTypesList', result.data)
                })
                .catch(error => console.log(error))
        }
    },
    getters: {
        getActiveArticleTypes: state => {
            return state.entityTypesList.filter(item => item.is_active)
        }
    }
}