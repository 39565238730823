<template>
    <div class="statistic__container">
        <h3 class="statistic__title">Статистика статей NTA с различных площадок</h3>
        <div class="statistic__social-networking--button-navigation">
            <router-link :to="{ name: 'visualize' , params: { display: 'chart'}}"
                         v-slot="{href, navigate, isActive}">
                <button @click="navigate" :class="[isActive && 'button--active']">График</button>
            </router-link>
            <router-link :to="{ name: 'table' , params: { display: 'table'}}"
                         v-slot="{href, navigate, isActive}"
            >
                <button @click="navigate" :class="[isActive && 'button--active']">Таблица</button>
            </router-link>
        </div>
        <div class="statistic__config">
            <statistic-select v-bind:statistic-config="statisticNetwork" :display-type="this.$route.params.display || 'chart'"></statistic-select>
            <statistic-select v-bind:statistic-config="statisticIndex" :display-type="this.$route.params.display || 'chart'"></statistic-select>
            <statistic-select v-bind:statistic-config="statisticType" :display-type="this.$route.params.display || 'chart'"></statistic-select>
        </div>
    </div>
</template>


<script>
import statisticSelect from "@/components/SocialNetworking/statisticSelect";
import statisticHeaderConfig from '@/config/statisticHeaderConfig';

export default {
  name: "statisticTableContent",
  props: ['componentName'],
  data() {
    return {
       statisticNetwork: statisticHeaderConfig['statisticNewtworks'],
       statisticIndex: statisticHeaderConfig['statisticIndex'],
       statisticType: statisticHeaderConfig['statisticType'],
       defaultDashboardType: 'chart'
    }
  },
  components: {
      statisticSelect
  }
}
</script>