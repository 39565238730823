<template>
    <div class="filter__container">
        <div class="filter__title">
        <p>{{title}}:</p>
            <div v-if="articleDataLoaded" class="filter__container-update">
                <p class="filter__text-update">Идёт обновление</p>
                <spinner size="15"></spinner>
            </div>
        </div>
        <div class="filter__wrapper__article-monitoring">
            <text-type-filter label-i-d="author__name"
                              label-text="Автор" ref="author__name" inputText="Фамилия автора"></text-type-filter>
            <text-type-filter label-i-d="search" label-text="Название статьи" inputText="Название статьи" ref="search"></text-type-filter>                
            <select-type-filter :select-filter-data="articleTBList" ref="tb"></select-type-filter>
            <select-type-filter :select-filter-data="articleStageList" ref="stages"></select-type-filter>
            <date-type-filter :select-filter-data="articleDatePublish" label-text="регистр." ref="date_published"></date-type-filter>
            <date-type-filter :select-filter-data="articleDateCheck" label-text="проверки" ref="date_check"></date-type-filter>

        </div>
        <div class="filter__wrapper__article-monitoring--lower_layer">
            <text-type-filter-links label-i-d="article_link" label-text="Первичная публикация" inputText="Ссылка" ref="search"></text-type-filter-links>
            <text-type-filter-links label-i-d="monitored_articles_nta__link" label-text="Заимствования" inputText="Ссылка" ref="search"></text-type-filter-links>

        </div>
        <div class="filter__container--bottom-part">
            <div class="filter__container-buttons">
                <button class="filter__button--clear" @click="dropFilter()">Сбросить все фильтры</button>
            </div>
        </div>
    </div>
</template>

<script>
import selectTypeFilter from "@/components/Assets/Filters/selectTypeFilter.vue";
import textTypeFilter from "@/components/Assets/Filters/textTypeFilter.vue";
import textTypeFilterLinks from "@/components/Assets/Filters/textTypeFilterLinks.vue";
import dateTypeFilter from "@/components/Assets/Filters/dateTypeFilter.vue";
import AuthService from "@/services/auth.service"
import { mapState } from 'vuex';
import Spinner from 'vue-simple-spinner'

export default {
    name: "articleRegistryFilter",
    props: ['title'],
    data() {
      return {
          articleDatePublish:{
              filterKeyDateAfter:"date_published_after",
              filterKeyDateBefore:"date_published_before"
          },
          articleDateCheck:{
              filterKeyDateAfter:"monitored_articles_nta__date_check_after",
              filterKeyDateBefore:"monitored_articles_nta__date_check_before"
          },
          articleStageList: {
            filterKey:'monitored_articles_nta__verification_stage',
            filterName:'Стадия',
            filterValuesList: [ { id: 0, value: '1' },
                                { id: 1, value: '2'},
                                { id: 2, value: '3'},]
          },
          articleHeadingList: {
            filterKey:'article_heading_id',
            filterName:'Рубрика',
            filterValuesList: []
          },
          articleTBList:{
              filterKey:'tb',
              filterName:'ТБ',
              filterValuesList:[{ id: 0, value:"ББ"},
                                { id: 1, value: "УБ"},
                                { id: 2, value:"ВВБ"},
                                { id: 3, value:"СЗБ"},
                                { id: 4, value:"СРБ"},
                                { id: 5, value:"ПБ"},
                                { id: 6, value:"СиББ"},
                                { id: 7, value:"ЦЧБ"},
                                { id: 8, value:"ЮЗБ"},
                                { id: 9, value:"ДВБ"},
                                { id: 10, value:"МБ"}]
          },
        }
    },
    components: {
       dateTypeFilter,
       selectTypeFilter,
       textTypeFilter,
       Spinner,
       textTypeFilterLinks,
       
    },
     computed: mapState ({
        articleTypesList: state => state.ArticlesTypesStore.entityTypesList,
        articleDataLoaded: state => state.ArticlesTable.articleDataLoaded,
  }),
  watch:{
      articleTypesList(){
        if(this.articleHeadingList.filterValuesList.length==0){
            this.articleTypesList.forEach(item=>this.articleHeadingList.filterValuesList.push({id:item.id,value:item.category_name}))
        }
      },
  },
  methods:{
      resetFilters(){
          this.$refs.author__name.setEmptyValue();
          this.$refs.stages.filteredValuesList=null;
          this.$refs.search.setEmptyValue();
          this.$refs.date_published.setEmptyDate();
          this.$refs.date_check.setEmptyDate();
          this.$refs.tb.filteredValuesList=null;
      },
      dropFilter(){
        this.resetFilters();
        this.$route.meta.query={page: 1}
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);///###
    },
    getUserArticles(){
         AuthService.getUserInfo().then(response => {
            this.$route.meta.query={...this.$route.meta.query, created_by: response.email, page: 1}
            this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
        })
    },
  }
}
</script>

<style scoped>

</style>