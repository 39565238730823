<template>
    <div class="filter__date">
          <label :for="selectFilterData.filterKeyDateAfter" class="text--dateFrom">Дата {{labelText}} от:</label>
          <input :id="selectFilterData.filterKeyDateAfter" type="date" v-model="selectedDateFrom">
          <label :for="selectFilterData.filterKeyDateAfter" class="text--dateTo">до:</label>
          <input :id="selectFilterData.filterKeyDateAfter" type="date" v-model="selectedDateTo">
      </div>
</template>

<script>
export default {
  name: "dateTypeFilter",
  props:['selectFilterData','labelText'],
  data(){
    return{
      selectedDateFrom: null,
      selectedDateTo: null
    }
  },
  methods:{
    setFilterSelectedDateFrom(){
      let routeObject = {}
      routeObject[this.selectFilterData.filterKeyDateAfter] = this.selectedDateFrom;
      this.$route.meta.query={...this.$route.meta.query, ...routeObject}
      this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
    setFilterSelectedDateTo(){
      let routeObject = {}
      routeObject[this.selectFilterData.filterKeyDateBefore] = this.selectedDateTo;
      this.$route.meta.query={...this.$route.meta.query, ...routeObject}
      this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
    setEmptyDate(){
      this.selectedDateFrom=null;
      this.selectedDateTo=null;
    },
    resetQueryDate(queryKey){
        delete this.$route.meta.query[queryKey];
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);///###
    }
  },
  watch:{
    selectedDateFrom(){
      if(this.selectedDateFrom==null||this.selectedDateFrom==''){
        this.resetQueryDate(this.selectFilterData.filterKeyDateAfter);
      }else{
        this.setFilterSelectedDateFrom()
      }
    },
    selectedDateTo(){
      if(this.selectedDateFrom==null||this.selectedDateFrom==''){
        this.resetQueryDate(this.selectFilterData.filterKeyDateBefore);
      }else{
      this.setFilterSelectedDateTo()
      }
    }
  }
}
</script>

<style scoped>

</style>