<template>
  <div class="filter__entered-option" v-if="paramData !== null">
    <div class="filter__entered-option-content">
      <div>
          {{paramData}}
      </div>
      <div class="filter__entered-option-close"
           v-on:click="removeFilteredParamFromQuery">
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "filterSelectedBlock",
  props: ['paramData'],
  methods: {
     removeFilteredParamFromQuery(){
        this.$parent.resetFilters();
     }
  }
}
</script>

<style scoped>

</style>