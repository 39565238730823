<template>
<div>
    <p>Страничка не найдена</p>
</div>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

<style scoped>

</style>