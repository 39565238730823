import axios from "axios";
import authHeader from "@/services/auth-header";
import config from '@/services/config.service'

const API_URL = config['API_LOCATION'] + 'api/'

class SocialNetworkingService {

    async getCommonNetworksStat(query) {
        return await axios.get(API_URL + 'statistics/common-stat/',{
            params: query
        }, {
            headers: authHeader()
        })
    }

    async getDynamicNetworksStat(query) {
        return await axios.get(API_URL + 'statistics/dynamic-stat/',{
            params: query
        }, {
            headers: authHeader()
        })
    }

    async getArticleDynamicStat(query) {
        return await axios.get(API_URL + 'statistics/article-dynamic-table/',{
            params: query
        }, {
            headers: authHeader()
        })
    }

    async getArticleCommonStat(query) {
        return await axios.get(API_URL + 'statistics/common-articles-table/',{
            params: query
        }, {
            headers: authHeader()
        })
    }

}

export default new SocialNetworkingService();
