<template>
            <div class="article-registry__table-row article-registry__table-hovered" 
                :class="[ArticleRow.is_habr_vc ? active : '']">
            <div class="article-registry__table-sell--id" v-html="ArticleRow.id"></div>
            <div class="article-registry__table-sell--authors">
                    <p v-for="author in ArticleRow.author"
                     v-bind:key = "author.id"
                    > {{ author.name }} 
                      {{ author.surname.charAt(0) + '.'}}
                      <!-- {{author.city }} -->
                    </p>
            </div>
            <div class="article-registry__table-sell--heading" v-html="ArticleRow.article_heading.category_name">Рубрика</div>
            <div class="article-registry__table-sell--status" v-html="ArticleRow.status">Статус</div>
            <div class="article-registry__table-sell--big" v-html="ArticleRow.article_name">Название</div>
            <div class="article-registry__table-sell--medium" v-html="articleDateAdded">Дата добавления</div>
            <div class="article-registry__table-sell--medium" v-html="articleDatePublish">Дата публикации</div>
            <div class="article-registry__table-sell--tb" v-html="ArticleRow.tb">ТБ</div>
            <div class="article-registry__table-sell--tb-checker" v-html="articleTB">ТБ рецензент</div>
            <div class="article-registry__table-sell--redactor-name">
                    <p v-for="tech in ArticleRow.tech_specialist"
                     v-bind:key = "tech.id"
                    > {{ tech.name }}
                      {{ tech.surname}}
                      {{ tech.patronymic }}
                    </p>
            </div>
<!--            <div class="article-registry__table-sell&#45;&#45;score" v-html="ArticleRow.article_heading.category_score">ТБ</div>-->
<!--            <div class="article-registry__table-sell&#45;&#45;score" v-html="ArticleRow.article_additional_points">ТБ</div>-->
<!--            <div class="article-registry__table-sell&#45;&#45;medium" v-html="ArticleRow.article_additional_commentary">ТБ</div>-->
        </div>




</template>

<script>

// import Article from "@/models/article";
import moment from 'moment';

export default {
  name: "ArticleRow",
  props: ['ArticleRow'],
  data() {
    return {
      active: 'activity__registry-highlighting',
      // article: new Article(this.ArticleRow),
      articleTB: '',
      articleDateAdded:'',
      articleDatePublish:''
    }
  },
  created() {
    if(this.ArticleRow.tb_responsible_name !== null) {
      this.articleTB = this.ArticleRow.tb_responsible_name + ', '
    }
    if(this.ArticleRow.tb_responsible !== null) {
        this.articleTB += this.ArticleRow.tb_responsible
    }
    this.articleDateAdded = moment(new Date(this.ArticleRow.date_added)).format("DD-MM-YYYY")
    if(this.ArticleRow.date_published !== null) {
      this.articleDatePublish = moment(new Date(this.ArticleRow.date_published)).format("DD-MM-YYYY")
    }
  }
}

</script>
