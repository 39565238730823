import Vue from 'vue';
import Vuex from 'vuex';
import { modalArticle } from "@/store/modalArticle.module";
import { modalActivity } from "@/store/modalActivity.module";
import { modalAuthors } from "@/store/modalAuthors.module";
import { modalTechs } from "@/store/modalTechspecs.module";
import { auth } from "@/store/auth.module";
import ArticlesTable from '@/store/ArticlesTable';
import ArticlesTypesStore from '@/store/article/ArticlesTypesStore';
import { UserData } from "@/store/user.module";
import { analyticsDashboard } from "@/store/analyticsDashboard.module";
import { analyticsTable } from "@/store/analyticsTable.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        ArticlesTypesStore,
        ArticlesTable,
        UserData,
        modalArticle,
        modalActivity,
        modalAuthors,
        modalTechs,
        analyticsDashboard,
        analyticsTable,
    },
});
