<template>
<div class="checkbox__container">
                <div class="checkbox__wrapper">
                    <input  type="checkbox" @change="filterContentFromAPI({is_habr_vc:filteredValue,'page': 1})" 
                    id="checkbox" v-model="filteredValue">
                    <label for="checkbox" >{{labelText}}</label>
                </div>
              </div>
</template>

<script>
export default {
name: "checkboxTypeFilter",
props:['labelText'],
data() {
    return {
        filteredValue:''
    }
  },
methods:{
    filterContentFromAPI(routeObject) {
        this.$route.meta.query={...this.$route.meta.query, ...routeObject}
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
}
}
</script>

<style scoped>

</style>