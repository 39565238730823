<template>
     <div class="statistic__select-wrapper">
          <label v-bind:for=statisticConfig.id>{{statisticConfig['label']}}</label>
          <select v-bind:id=statisticConfig.id
                  class="statistic__select-dropdown"
                  @change="updateRequestProperties(statisticConfig['queryParamName'], $event.target)"
          >
                 <option v-for="(value, key, index) in statisticConfig.select_list" :key="`${ key }-${ index }`"
                         :value=value.selectValue
                 >
                        {{value.selectName}}
                 </option>
          </select>
     </div>
</template>

<script>

export default {
  name: "statisticSelect",
  props: ['statisticConfig', 'displayType'],
  methods: {
      updateRequestProperties(queryParamName, event){
          let selectStoreConfig = {
            'chart': {
               'requestType': () => this.$store.dispatch('analyticsDashboard/setRequestType', event.value),
               'requestProperties': () => this.$store.dispatch('analyticsDashboard/setRequestProperties',
                {
                      'keyToUpdate': queryParamName,
                      'valueToUpdate': event.value
                })
            },
            'table': {
              'requestType': () => this.$store.dispatch('analyticsTable/setRequestType', event.value),
              'requestProperties': () => this.$store.dispatch('analyticsTable/setRequestProperties',
                {
                      'keyToUpdate': queryParamName,
                      'valueToUpdate': event.value
                })
            }
          }

          if(queryParamName === undefined) {
              selectStoreConfig[this.displayType]['requestType']()
          } else {
              selectStoreConfig[this.displayType]['requestProperties']()
          }
      },
   }

}
</script>

<style scoped>

</style>