<template>
  <div class="statistic-contaner">
    <h3 class="statistic-contaner--text-char">Статистика по опубликованным статьям</h3>
    <div class="statistic-contaner--white">
      <BarChart :chartData="chartData" :options="options"/>
    </div>
  </div>
 </template>

 <script>
import BarChart from '@/components/Statistic/BarChart';
import {Chart} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.defaults.global.plugins.datalabels.anchor = 'end';
Chart.defaults.global.plugins.datalabels.align = 'end';
Chart.defaults.global.plugins.datalabels.clamp = true;

export default {
  name: "ReestrChart",
  props: ['articlesData'],
  plugins: [ChartDataLabels],

  components: {
    BarChart
  },
  data () {
    return {
      chartData: null,
      options: null,
    }
  },
  mounted() {
     this.fillData()
  },
  methods: {
    fillData() {
       this.chartData = {
          labels: this.articlesData.tb,
            datasets: [
              {
                label: 'Количество статей',
                backgroundColor: '#5e72e4',
                data: this.articlesData.counter,
                datalabels: {                  
                  color: 'white',
                  fontColor: "#CCC",
                }
              },
            ]
       }
       this.options =  {
                  scales: {
                xAxes: [{
                  stacked: true,
                  gridLines: {
                      display: true,
                      color: "rgb(73, 54, 141)"
                  },
                   ticks: {
                  fontColor: "#CCC", // this here
                },
                }],
                yAxes: [{
                  stacked: true,
                  gridLines: {
                      display: true,
                      color: "rgb(73, 54, 141)"
                  },
                  ticks: {
                  fontColor: "#CCC", // this here
                },
                }]
            },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    fontColor: "rgb(243, 243, 243)",
                }
                  
              },
          layout: {
            padding: {
              top: 30
            }
          },
        plugins: {
            datalabels: {
              color: 'white'
            }
        }
      }
    }
  },
  watch: {
    articlesData: function() {
      this.fillData()
    }
  }
}
</script>
