export default class Activity {
    constructor(propsObject) {
        this.id = propsObject.id
        this.tb = propsObject.tb
        this.quarter = propsObject.quarter
        this.author = propsObject.author
        this.activity_type = propsObject.activity_type;
        this.activity_name = propsObject.activity_name;
        this.activity_status = propsObject.activity_status || 'Новая'
        this.activity_date = propsObject.activity_date
        this.points = propsObject.points || 1;
        this.additional_points = propsObject.additional_points;
        this.commentary_ca = propsObject.commentary_ca;
        this.activity_type_id = this.getActivityTypeID(propsObject)
        this.is_staff = propsObject.is_staff || false
    }

    getActivityTypeID(item) {
        try {
            return item.activity_type_id.id
        } catch {
            return 1
        }
    }
}
