<template>
  <div class="home__content-block">
    <img src="home/3.png" alt="">
    <h2 class="home__content-block-title">Перечень основных мероприятий</h2>
    <div class="home__content-block-text-wrap">
      <ul class="home__content-block-list">
        <!-- <li><font-awesome-icon icon="calendar-check" />Каждый четверг в реестре будут обновляться статусы статей, в соответствии с решением рецензеров.</li> -->
        <li>При размещении поста в реестр автору необходимо указать согласен ли он, что его ФИО будут указаны в публикации на Habr/VC</li>
       </ul>
    </div>

  </div>
</template>

<script>


export default {
  name: "Events",
}

</script>

<style scoped>

</style>
