<template>
      <div class="statistic-contaner">
        <h3 class="statistic-contaner--text">Состояние реестра:</h3>
        <div v-if="articleDataLoaded">
           <spinner></spinner>
        </div>
        <div v-else>
              <div class="statistic-wrapper">
                  <stat-section v-for="(item, index) in articleStatusStat"
                                :key="index"
                                :data="item.counter"
                                :name="item.status"
                  ></stat-section>
              </div>
              <h3 class="statistic-contaner--text">Статистика по рецензерам (кол.во проверенных статей)</h3>
               <div class="statistic-wrapper">
                  <stat-section v-for="(item, index) in articleReviewersStat"
                                :key="index"
                                :data="item.counter"
                                :name="item.tb_responsible"
                  ></stat-section>
              </div>
        </div>
        <articles-bar-chart></articles-bar-chart>
      </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Spinner from 'vue-simple-spinner'
import articlesBarChart from "./articlesBarChart";
import statSection from "./statSection";

export default {
  name: "StatisticBlock",
  mounted() {
    this.$store.dispatch('articleRegistryState/getArticleData');
    this.$store.dispatch('articleRegistryPublishedState/getArticleData')
  },
  computed: {
    ...mapState({
      articleDataLoaded: state => state.articleRegistryState.dataLoaded,
      articleData: state => state.articleRegistryState.data,
      articlePublishedData: state => state.articleRegistryPublishedState.data
    }),
    ...mapGetters({
      articleStatusStat: 'articleRegistryState/getStatusStat',
      articleReviewersStat: 'articleRegistryState/getReviewersStat'
    })
  },
  components: {
    statSection,
    Spinner,
    articlesBarChart
  }
}
</script>

<style scoped>

</style>
