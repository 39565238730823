<template>
       <div class="nav-menu">
        <div class="nav-menu__content" :class="{  'nav-menu__content--active': !sidebarActive }">
          <ul> 
            <li>
                <router-link to="/Home"><font-awesome-icon icon="university" />
                    <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Главная</span>
                    </transition>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: '/Articles', query: { page: '1' }}"> <font-awesome-icon icon="server" />
                     <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Реестр статей</span>
                    </transition>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: '/Activity', query: { page: '1' }}" ><font-awesome-icon icon="chart-bar" />
                   <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Реестр активностей</span>
                    </transition>
                </router-link>
            </li>
            <li>
                <router-link to="/Stastics"><font-awesome-icon icon="tasks" />
                    <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Статистика реестра</span>
                    </transition>
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/Team"><font-awesome-icon icon="users" />
                     <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">О команде</span>
                    </transition>
                </router-link>
            </li> -->
            <li>
                <router-link :to="{ name: 'Commentary', query: { id: 1, pageID: 1 }}"><font-awesome-icon icon="comments" />
                    <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Комментарии</span>
                    </transition>
                </router-link>
            </li>
            <li>
              <router-link to="/Rating"><font-awesome-icon icon="poll" />
                   <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Рейтинг NTA</span>
                    </transition>
              </router-link>
            </li>
            <li>
              <router-link  :to="{ name: 'visualize'}" ><font-awesome-icon icon="chart-line" />
                     <transition name="slide-fade">
                       
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Аналитика социальных сетей</span>
                    </transition>
               </router-link>
            </li>
            <!-- v-if="modalArticleData.is_staff"to="/monitoring?page=1"  -->
            
            <li  >
              <router-link :to="{ path: '/monitoring', query: { page: '1' }}" ><font-awesome-icon icon="binoculars" />
                     <transition name="slide-fade">
                        <span v-if="sidebarActive" class="nav-menu__content-link-text">Мониторинг статей  NTA</span>
                    </transition>
               </router-link>
            </li>
          </ul>
          <div class="nav-menu__profile">
            <div class="nav-menu__profile--container ">
              <div class="nav-menu__profile--content">
              <div>
                  <img src="@/static/default-img.png" alt="" class="img-fluid rounded-circle " >
              </div>
              <div> 
                <transition name="slide-fade">
                  <span v-if="sidebarActive">{{surname.name}} {{surname.surname}}</span>
                </transition>
              </div>
              </div>
              <div class="nav-menu__profile-logout--content" >
                <div v-on:click="userLogout">
                <font-awesome-icon class="nav-menu__logout--text" icon="sign-out-alt"/>
                  <transition name="slide-fade">
                    <span v-if="sidebarActive" class="nav-menu__logout--text">Выйти</span>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "Sidebar",
  props:["sidebarActive","surname"],
  methods: {
    userLogout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  computed:mapState ({
        modalArticleData: state => state.modalArticle.article,
      })

}
</script>

<style scoped>
.nav-menu .router-link-active {
  color: #2dce89;
}
</style>