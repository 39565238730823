<template>
  <div class="modal__authors-table-row">
    <p class="id">{{tech.id}}</p>
    <p class="name">{{tech.name}}</p>
    <p class="surname">{{tech.surname}}</p>
    <p class="patronymic">{{tech.patronymic}}</p>
    <!-- <p class="tb">{{tech.tb}}</p> -->
    <!-- <p class="city">{{tech.city}}</p> -->
  </div>

</template>

<script>
import Techspec from "@/models/tech";


export default {
  name: "ModalTechsRow",
  props: ['TechRow'],
  data() {
    return {
      tech: new Techspec(
        this.TechRow.id,
        this.TechRow.name,
        this.TechRow.surname,
        this.TechRow.patronymic,
        //  this.TechRow.city
        )
    }
  }
}
</script>

<style scoped>

</style>