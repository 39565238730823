import BaseAnalyticsModule from "./analytics/BaseAnalyticsModule";
import SocialNetworkingService from "@/services/social-networking.service";


const state = {
    ...BaseAnalyticsModule.state,
    dataLabels: [],
    dataValues: [],
    dataType:'',
}

const actions = {
    ...BaseAnalyticsModule.actions,
    getStatisticData({commit, state}) {
        let statisticTypes = {
            'dynamic': () => SocialNetworkingService.getDynamicNetworksStat(state.requestQuery),
            'common': () => SocialNetworkingService.getCommonNetworksStat(state.requestQuery),

        }
        statisticTypes[state.requestAggregationType]().then(response => {
            commit('updateStatisticStoreData', {...response.data, statistic_type:state.requestQuery.statistic_type})
        })
        .catch(err=>{
            console.log(err)
            commit('updateStatisticStoreData', {labels:[],score:[], statistic_type:state.requestQuery.statistic_type})
        })
        .catch(err=>{
            console.log(err)
            commit('updateStatisticStoreData', {labels:[],score:[], statistic_type:state.requestQuery.statistic_type})
        })
    }
}

const mutations = {
    ...BaseAnalyticsModule.mutations,
    updateStatisticStoreData(state, data){
        state.dataLabels = data.labels
        state.dataValues = data.score
        const binds={
            'post_views': 'Просмотры',
            'post_likes': 'Лайки'
        }
        state.dataType = binds[data.statistic_type]
    }
}

export const analyticsDashboard = {
    namespaced: true,
    state,
    actions,
    mutations
}