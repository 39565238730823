<template>
    <div class="barchart">
        <div v-if="dataLoaded">
          <spinner></spinner>
        </div>
        <div v-else class="barchart__header">
            <div class="barchart__header-filter">
                <barchart-filter-period></barchart-filter-period>
                <reestr-chart :articles-data="articlePublishedData"></reestr-chart>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import barchartFilterPeriod from "./articlesBarChart/barchartFilterPeriod";
import ReestrChart from "./ReestrChart";
import Spinner from 'vue-simple-spinner'

export default {
name: "articlesBarChart",
  props: ['dataLoaded', 'articleDataLength'],
  computed: {
    ...mapState({
      articlePublishedData: state => state.articleRegistryPublishedState.data
    }),
  },
  components: {
      barchartFilterPeriod,
      ReestrChart,
      Spinner
  }
}
</script>

<style scoped>

</style>