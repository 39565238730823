<template>
    <div>
         <MonitoringArticlesRows
         v-for="(item,index) in ArticleColumn.monitored_articles_nta" :key="index"
                :ArticleData="ArticleColumn"
                v-bind:ArticleRow ="item"
         ></MonitoringArticlesRows>
    </div>
</template>


<script>
// import Article from "@/models/article";
import MonitoringArticlesRows from "./MonitoringArticlesRows";

export default {
  name: "ArticleColumn",
  props: ['ArticleColumn'],
  data() {
    return {
      active: 'activity__registry-highlighting',
    }
  },
  components:{
      MonitoringArticlesRows
  },
}

</script>
