import { render, staticRenderFns } from "./ArticleReviewer.vue?vue&type=template&id=110b4002&scoped=true&"
import script from "./ArticleReviewer.vue?vue&type=script&lang=js&"
export * from "./ArticleReviewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110b4002",
  null
  
)

export default component.exports