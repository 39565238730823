<template>
  <div class="container">
      <Header></Header>
    <div class="content">
      <div class="container-header">
          <p>РЕЕСТР АКТИВНОСТЕЙ</p>
        </div>
      <div class="statistic__block">
        <StatisticBlock />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import StatisticBlock from "@/components/Statistic/StatisticBlock";
import { articleRegistryState } from "@/store/articleRegistryState/articleRegistryState";
import { articleRegistryPublishedState } from "@/store/articleRegistryState/articleRegistryPublishedState";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    StatisticBlock,
  },

  created() {
    this.$store.registerModule("articleRegistryState", articleRegistryState);
    this.$store.registerModule("articleRegistryPublishedState", articleRegistryPublishedState);
  },
  beforeDestroy() {
    this.$store.unregisterModule("articleRegistryState", articleRegistryState);
    this.$store.unregisterModule("articleRegistryPublishedState", articleRegistryPublishedState);
  },
}
</script>

<style scoped>
</style>