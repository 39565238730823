import axios from "axios";
import authHeader from "@/services/auth-header";
import config from '@/services/config.service'

const API_ARTICLES_XLSX = config['API_LOCATION'] + 'api/articles/';
const API_ACTIVITY_XLSX = config['API_LOCATION'] + 'api/activity/';

class FileHandler {
    
    async downloadArticleStat() {
        return await axios.get(API_ARTICLES_XLSX + 'download-set/all', {
             headers: authHeader(),
             responseType: "blob"
        })
    }
    async downloadArticleMonitoringStat() {
        return await axios.get(API_ARTICLES_XLSX + 'download-monitoring-set/all', {
             headers: authHeader(),
             responseType: "blob"
        })
    }

    async downloadActivitySet() {
          return await axios.get(API_ACTIVITY_XLSX + 'download-activity-set/', {
             headers: authHeader(),
             responseType: "blob"
        })
    }

    saveReceivedfile(response, filename, extension) {
        let blob = new Blob([response.data]);
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //create the download url
        downloadElement.href = href;
        downloadElement.download = filename + extension; //the name of the downloaded file
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click to file
        document.body.removeChild(downloadElement); //remove the element
        window.URL.revokeObjectURL(href);
    }
}

export default new FileHandler()

