<template>
  <div>
      <div class="article-registry__table" >
          <div class="article-registry__load-excel">
          </div>
          <div class="article-registry__filters">
              <article-registry-filter title="Фильтры">
              </article-registry-filter>
          </div>
           <div class="article-registry__page-control">
              <button v-on:click="loadPreviousPage"
                       v-if="isPreviousButtonVisible"
                       class="article-registry__page-control-previous">
                Предыдущая страница
              </button>
              <button v-on:click="loadNextPage"
                       v-if="isNextButtonVisible"
                       class="article-registry__page-control-next">
                 Следующая страница
               </button>
          </div>
        <div class="article-registry__table-content">
          <div class="article-registry__table-row article-registry__table-row--head">
            <div class="article-registry__table-sell--id">№</div>
            <div class="article-registry__table-sell--authors">Автор</div>
            <div class="article-registry__table-sell--heading">Рубрика</div>
            <div class="article-registry__table-sell--status">Статус</div>
            <div class="article-registry__table-sell--big">Название</div>
            <div class="article-registry__table-sell--medium">Дата добавления</div>
            <div class="article-registry__table-sell--medium">Дата публикации</div>
            <div class="article-registry__table-sell--tb">ТБ</div>
            <div class="article-registry__table-sell--tb-checker">ТБ рецензер</div>
            <div class="article-registry__table-sell--redactor-name">Тех. рецензент</div>
<!--            <div class="article-registry__table-sell&#45;&#45;score">Балл</div>-->
<!--            <div class="article-registry__table-sell&#45;&#45;score">Доп.<br>балл</div>-->
<!--            <div class="article-registry__table-sell&#45;&#45;medium">Доп.<br>балл<br> коммент</div>-->
        </div>
         <ArticleRow
           v-for="article in articleData"
           v-bind:key = "article.id"
           v-bind:ArticleRow ="article"
           @click.native="showArticleModal(article.id)"
         ></ArticleRow>
        </div>
        <div class="article-registry__page-control">
               <button v-on:click="loadPreviousPage"
                     v-if="isPreviousButtonVisible" class="article-registry__page-control-previous">Предыдущая страница</button>
               <button v-on:click="loadNextPage"
                       v-if="isNextButtonVisible"
                       class="article-registry__page-control-next">Следующая страница</button>
        </div>
      </div>
  </div>
</template>
<script>
import ArticleRow from "@/components/ArticalsRegistry/ArticleRow";
import AuthService from "@/services/auth.service"
import articleRegistryFilter from "./articleRegistryFilters/articleRegistryFilter";
import { mapState } from 'vuex';

export default {
  name: "ArticleTable",
  data() {
    return {
      page: this.$route.query.page
    }
  },
  components: {
    ArticleRow,
    articleRegistryFilter,
    
  },
  mounted() {
    this.$store.dispatch('ArticlesTable/getArticles', this.$route.query.page);
  },
  computed: mapState ({
    articleDataLoaded: state => state.ArticlesTable.articleDataLoaded,
    articleData: state => state.ArticlesTable.articles,
    isPreviousButtonVisible: state => state.ArticlesTable.articlePreviousButton,
    isNextButtonVisible: state => state.ArticlesTable.articleNextButton,
  }),
  methods: {
    showArticleModal(id) {
        this.$store.dispatch('modalArticle/getArticleData', id)
    },
    getUserArticles() {
        AuthService.getUserInfo().then(response => {
             this.filterContentFromAPI({created_by: response.email, page: 1})
        })
    },
    loadNextPage() {
      window.scrollTo(0,0)
      let newPageNumber = parseInt(this.$route.meta.query.page) + 1
      this.filterContentFromAPI({page: newPageNumber})
    },
    loadPreviousPage() {
      let newPageNumber = parseInt(this.$route.meta.query.page)-1
      this.filterContentFromAPI({page: newPageNumber})
    },
    filterContentFromAPI(routeObject) {
      this.$route.meta.query={...this.$route.meta.query, ...routeObject}
      this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
  }
}
</script>

<style scoped>
</style>
