<template>
    <div class="filter__container">
        <div class="filter__title">
        <p>{{title}}:</p>
            <div v-if="articleDataLoaded" class="filter__container-update">
                <p class="filter__text-update">Идёт обновление</p>
                <spinner size="15"></spinner>
            </div>
        </div>
        <div class="filter__wrapper__article-registry">
            <text-type-filter label-i-d="author__name"
                              label-text="Автор" ref="author__name" inputText="Фамилия автора"></text-type-filter>
            <select-type-filter :select-filter-data="articleHeadingList" ref="article_heading_id"></select-type-filter>
            <select-type-filter :select-filter-data="articleStatusList" ref="status"></select-type-filter>
            <text-type-filter label-i-d="search" label-text="Название статьи" inputText="Название статьи" ref="search"></text-type-filter>
            <date-type-filter :select-filter-data="articleDateAdded" label-text="добав." ref="date_published"></date-type-filter>
            <date-type-filter :select-filter-data="articleDatePublish" label-text="публ." ref="date_added"></date-type-filter>
            <select-type-filter :select-filter-data="articleTBList" ref="tb"></select-type-filter>
            <select-type-filter :select-filter-data="articleTBRewList"  ref="tb_responsible"></select-type-filter>
        </div>
        <div class="filter__container--bottom-part">
            <div>
                <checkbox-type-filter  ref="is_habr_vc" label-text=" Статьи для Хабра и VC "></checkbox-type-filter>
            </div>
            <div class="filter__container-buttons">
                <button class="filter__button--article" @click="getUserArticles()">Отфильтровать мои статьи</button>
                <button class="filter__button--clear" @click="dropFilter()">Сбросить все фильтры</button>
            </div>
        </div>
    </div>
</template>

<script>
import selectTypeFilter from "@/components/Assets/Filters/selectTypeFilter.vue";
import textTypeFilter from "@/components/Assets/Filters/textTypeFilter.vue";
import dateTypeFilter from "@/components/Assets/Filters/dateTypeFilter.vue";
import checkboxTypeFilter from "@/components/Assets/Filters/checkboxTypeFilter.vue";
import AuthService from "@/services/auth.service"
import { mapState } from 'vuex';
import Spinner from 'vue-simple-spinner'

export default {
    name: "articleRegistryFilter",
    props: ['title'],
    data() {
      return {
          articleDatePublish:{
              filterKeyDateAfter:"date_published_after",
              filterKeyDateBefore:"date_published_before"
          },
          articleDateAdded:{
              filterKeyDateAfter:"date_added_after",
              filterKeyDateBefore:"date_added_before"
          },
          articleStatusList: {
            filterKey:'status',
            filterName:'Статус',
            filterValuesList: [ { id: 0, value: "Новый" },
                                { id: 1, value: "Принято в работу" },
                                { id: 2, value: "На доработку"},
                                { id: 3, value: "Согласовано"},
                                { id: 4, value: "Опубликовано"},
                                { id: 5, value:  "Отклонено"}]
          },
          articleHeadingList: {
            filterKey:'article_heading_id',
            filterName:'Рубрика',
            filterValuesList: []
          },
          articleTBList:{
              filterKey:'tb',
              filterName:'ТБ',
              filterValuesList:[{ id: 0, value:"ББ"},
                                { id: 1, value: "УБ"},
                                { id: 2, value:"ВВБ"},
                                { id: 3, value:"СЗБ"},
                                { id: 4, value:"СРБ"},
                                { id: 5, value:"ПБ"},
                                { id: 6, value:"СибБ"},
                                { id: 7, value:"ЦЧБ"},
                                { id: 8, value:"ЮЗБ"},
                                { id: 9, value:"ДВБ"},
                                { id: 10, value:"ЦА"},
                                { id: 11, value:"МБ"}]
          },
          articleTBRewList:{
              filterKey:'tb_responsible',
              filterName:'Рецензер',
              filterValuesList:[{ id: 0, value:"ББ"},
                                { id: 1, value:"УБ"},
                                { id: 2, value:"ВВБ"},
                                { id: 3, value:"СРБ"},
                                { id: 4, value:"ЦЧБ"},
                                { id: 5, value:"ЮЗБ"},
                                { id: 6, value:"ПБ"}]
          },
        }
    },
    components: {
       dateTypeFilter,
       selectTypeFilter,
       textTypeFilter,
       Spinner,
       checkboxTypeFilter
       
    },
     computed: mapState ({
        articleTypesList: state => state.ArticlesTypesStore.entityTypesList,
        articleDataLoaded: state => state.ArticlesTable.articleDataLoaded,
  }),
  watch:{
      articleTypesList(){
        if(this.articleHeadingList.filterValuesList.length==0){
            this.articleTypesList.forEach(item=>this.articleHeadingList.filterValuesList.push({id:item.id,value:item.category_name}))
        }
      },
      
  },
  methods:{
      resetFilters(){
          this.$refs.article_heading_id.filteredValuesList=null;
          this.$refs.author__name.setEmptyValue();
          this.$refs.status.filteredValuesList=null;
          this.$refs.search.setEmptyValue();
          this.$refs.date_published.setEmptyDate();
          this.$refs.date_added.setEmptyDate();
          this.$refs.tb.filteredValuesList=null;
          this.$refs.tb_responsible.filteredValuesList=null;
          this.$refs.is_habr_vc.filteredValue=null;
      },
      dropFilter(){
        this.resetFilters();
        this.$route.meta.query={page: 1}
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
        
    },
    getUserArticles(){
         AuthService.getUserInfo().then(response => {
            this.$route.meta.query={...this.$route.meta.query, created_by: response.email, page: 1}
            this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
        })
    },
  }
}
</script>

<style scoped>

</style>