<template>
  <div class="container">
     <Header></Header>
      <div class='content'>
        <div class="container-header container__banner">
          <p>РЕЕСТР СТАТЕЙ</p>
<!--          <div >-->
<!--            &lt;!&ndash; Удалить 1.06.22  стили ниже&ndash;&gt;-->
<!--            <div class="content__banner">-->
<!--            <p class="content__banner&#45;&#45;text">-->
<!--              ЦК приостановил рассмотрение новых статей ТБ, которые имеют > 4 согласованных постов-->
<!--            </p></div>-->
<!--   
         </div>-->

<div v-if="showModal" class="modal-shadow" @click.self="closeModal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">
                  <b>Добрый день, коллеги!</b>
                </slot>
              </div>
              <div class="modal-body">
                <slot name="body">ЦК работает над улучшением контента NTA и формирует дополнительные рекомендации к качеству постов.
                  <br>
                  <br>
                  С 24.03.2023:
                  <ul>
                    <li>1. Не загружать новые посты на Сервис NTA</li>
                    <li>2. Стоп по постам «на проверке ЦК» до формирования доп. рекомендаций</li>
                  </ul>
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="showModal=!showModal">
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
    </div>
          <p></p>
        </div>
          <div class="article-registry">
               <div class="article-registry__header">
                  <div>
                      <button v-on:click="createNewArticle" class="article-registry__add-post">
                        <font-awesome-icon icon="plus" />
                       Добавить статью
                      </button>
                      
                      <button @click="downloadCheckList"  class="article-registry__check-list"  type="submit">
                        Чек лиcт для самопроверки
                      </button>
                      <!-- <a id="linkExls" href="https://service.newtechaudit.ru/doc/1645766922375_Чек лист.xlsx" download hidden></a> -->
                      <a id="linkExls" href="https://service.newtechaudit.ru/doc/Чек лист ver2.xlsx" download hidden></a>
                  </div>
                      <load-excel-button v-bind:load-type="1"></load-excel-button>
            </div>
      <!--поиск по постам-->
      <ArticleTable ref="ArticleTable"></ArticleTable>
    </div>
        </div>
        <Footer></Footer>
        <modal_article
            v-if="modalVisible"
        ></modal_article>
        <div class="modal__background" v-if="modalVisible"></div>
  </div>



</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import ArticleTable from "@/components/ArticalsRegistry/ArticleTable";
import modal_article from "@/components/ArticalsRegistry/modal_article";
import {mapState} from "vuex";
import loadExcelButton from "@/components/Assets/Buttons/loadExcelButton";

export default {
  name: "ArticlesRegistry",
  data() {
    return {
      searchValue: '',
      searchProps: '',showModal:true
    }
  },
  created() {
    this.$route.meta.query={page:1}
  },
  mounted() {
    this.$store.dispatch('ArticlesTypesStore/getTypesListFromAPI', 'articles');
  },
  computed: mapState({
    modalVisible: state => state.modalArticle.isArticleModalVisible
  }),
  components: {
    Header,
    Footer,
    ArticleTable,
    modal_article,
    loadExcelButton
  },

  methods: {
    createNewArticle: function () {
      this.$store.dispatch('modalArticle/createNewArticle')
    },
    downloadCheckList(){
      document.getElementById('linkExls').click()
      // var iframe = document.getElementById('invisible');
      // iframe.src = "@/src/assets/doc/1645766922375_Чек лист.xlsx";
    }
  }
}
</script>

<style scoped>
.container__banner{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.content__banner{
  margin-top:10px;
  border: 2px solid yellow;
  border-radius: 8px;
}
.content__banner--text{
  padding: 10px;
  align-items: center;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 350px;
  margin: 0px auto;
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  padding: 10px;
  background: rgb(31, 184, 31);
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


</style>