<template>
  <div class="modal__add-article-set-tb-reviewer">
      <spinner v-if="spinnerVisible"></spinner>
      
      <!-- hh{{modalArticleData.tb_responsible_name}} -->
      <div v-if="modalArticleData.is_base_bank === 'true'"
           class="modal__add-article-set-tb-reviewer-wrapper"
      >
        <div>
          Выбрать ТБ рецензер
          <select v-model="tbResponsible">
             <option>ББ</option>
             <option>УБ</option>
             <option>СРБ</option>
             <option>ЦЧБ</option>
             <option>ВВБ</option>
             <option>ПБ</option>
             <option>ЮЗБ</option>
          </select>
        </div>

         <button v-on:click="setResponsibleTB" class="modal__add-article__set-redactor">
             Назначить
         </button>
      </div>
      <button v-if="modalArticleData.tb_responsible_name === null & modalArticleData.tb_responsible !== null"
              v-on:click="setArticleResponsible"
              class="modal__add-article__set-redactor"
              :class="{'active-button': isActive}">
              Взять статью в работу
      </button>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Spinner from 'vue-simple-spinner'
import ArticleService from "@/services/articles.service";


export default {
  name: "ArticleReviewer",
  data() {
    return {
      tbResponsible: null,
      spinnerVisible: false,
      isActive: false
    }
  },
  components: {
    Spinner
  },
  computed: mapState ({
    modalArticleData: state => state.modalArticle.article,
  }),
  methods: {
    setArticleResponsible() {
      this.isActive=true;
       ArticleService.setArticleResponsible(this.modalArticleData.id)
          .then(() => {
              this.$store.dispatch('modalArticle/getArticleData', this.modalArticleData.id)
           })
    },
    setResponsibleTB() {
      if (this.tbResponsible !== null) {
          ArticleService.addResponsibleTB(this.modalArticleData.id, this.tbResponsible)
              .then(() => {
                 this.$store.dispatch('modalArticle/getArticleData', this.modalArticleData.id)
              })
        }
      }
    }
}
</script>

<style scoped>

</style>