
<template>
  <div class="authorization">
    <router-view></router-view>
  </div>
</template>
<script>
/* Импортируем 4 компонента логина */
/*import Login from "@/components/Authorize/Login";
import Register from "@/components/Authorize/Register";*/
/*import RegisterSuccess from "@/components/Authorize/RegisterSuccess";
import RememberPass from "@/components/Authorize/RememberPass";*/


export default {
  name: "Authorize",

  /*data(): {
    currentTab: "Login",
    tabs: ["Login", "Register", "Remember"]
  }*/
}
</script>

<style scoped>

</style>