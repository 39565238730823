import axios from 'axios';
import authHeader from '@/services/auth-header';
import moment from 'moment';
import config from '@/services/config.service';
import { trimTags } from '../utils/trimTags';

const API_URL = config['API_LOCATION'] + 'api/articles/';

class ArticlesService {
  async uploadArticleFile(articleId, file) {
    let formData = new FormData();
    formData.append('file', file);
    return await axios.put(API_URL + 'uploadfile/' + articleId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async downloadArticleFile(articleId) {
    return await axios.get(API_URL + '/download/' + articleId, {
      responseType: 'blob',
    });
  }

  async setArticleResponsible(articleId) {
    return await axios.put(API_URL + '/take-article/' + articleId, '', {
      headers: authHeader(),
    });
  }

  async getAllArticles(query) {
    return await axios.get(API_URL + 'all', {
      params: query,
    });
  }

  async getAllMonitoredArticles(query) {
    return await axios.get(API_URL + 'monitored', {
      params: query,
    });
  }

  async getSingleArticle(id) {
    return await axios.get(API_URL + id, {
      headers: authHeader(),
    });
  }

  async getTypes() {
    return await axios.get(API_URL + 'get-article-types/', {
      headers: authHeader(),
    });
  }

  async saveArticleForm(article, articleAuthors, articleTechs) {
    if (article.id === 'null') {
      let articleToSend = {
        article_heading: { id: article.article_heading.id },
        status: article.status,
        article_name: article.article_name,
        article_link: 'article',
        author: articleAuthors,
        tech: articleTechs,
        tb: article.tb,
        is_habr_vc: article.is_habr_vc,
        is_publication_author: article.is_publication_author,
        rule_tag: trimTags(article.rule_tag),
        tools_tag: trimTags(article.tools_tag),
        libs_tag: trimTags(article.libs_tag),
      };

      if (
        article.article_commentary !== undefined &&
        article.article_commentary !== ''
      ) {
        articleToSend['article_commentary'] = article.article_commentary;
      }

      return await axios.post(API_URL + 'create', articleToSend, {
        headers: authHeader(),
      });
    } else {
      console.log(article.rule_tag);
      let articleToUpdate = {
        article: {
          article_heading: { id: article.article_heading.id },
          status: article.status,
          author: articleAuthors,
          article_name: article.article_name,
          tb: article.tb,
          is_habr_vc: article.is_habr_vc,
          is_publication_author: article.is_publication_author,
          tech_specialist: articleTechs,
          rule_tag: trimTags(article.rule_tag),
          tools_tag: trimTags(article.tools_tag),
          libs_tag: trimTags(article.libs_tag),
        },
      };
      if (
        article.article_commentary !== undefined &&
        article.article_commentary !== ''
      ) {
        articleToUpdate.article['article_commentary'] =
          article.article_commentary;
      }
      if (
        article.article_additional_points !== undefined &&
        article.article_additional_points > 0
      ) {
        articleToUpdate.article['article_additional_points'] =
          article.article_additional_points;
      }
      if (
        article.article_additional_commentary !== undefined &&
        article.article_additional_commentary !== ''
      ) {
        articleToUpdate.article['article_additional_commentary'] =
          article.article_additional_commentary;
      }
      if (article.date_published !== null) {
        articleToUpdate.article['date_published'] = moment(
          article.date_published
        ).format('YYYY-MM-DD HH:mm');
      }

      return await axios.put(
        API_URL + 'update/' + article.id,
        articleToUpdate,
        {
          headers: authHeader(),
        }
      );
    }
  }

  async saveArticleCommentary(articleId, commentaryText) {
    return await axios.post(
      API_URL + 'set-commentary/' + articleId,
      {
        text: commentaryText,
      },
      {
        headers: authHeader(),
      }
    );
  }

  async getPublishedArticlesStat(query) {
    return await axios.get(API_URL + 'statistics/get/published', {
      headers: authHeader(),
      params: query,
    });
  }

  async getArticlesRegistryState() {
    return await axios.get(API_URL + 'statistics/get/state', {
      headers: authHeader(),
    });
  }

  async addResponsibleTB(id, tb) {
    return await axios.put(
      API_URL + 'set-article-responsible/' + id,
      {
        tb_responsible: tb,
      },
      {
        headers: authHeader(),
      }
    );
  }

  async addNtaSocialWebs(query) {
    return await axios.post(API_URL + 'nta_social_webs/', query, {
      headers: authHeader(),
    });
  }
  async updateNtaSocialWebs(articleId, query) {
    return await axios.patch(
      API_URL + 'nta_social_webs/' + articleId + '/',
      query,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ArticlesService();
