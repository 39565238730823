import TechService from "@/services/techspecs.service"


export const modalTechs = {
    namespaced: true,
    state: () => ({
        techsList: [],
        modalTechsLoaded: false,
        modalVisible: false,
    }),
    mutations: {
        loadingTechsStatus (state, newLoadingStatus) {
            state.modalTechsLoaded = newLoadingStatus
        },
        showAllTechs(state, techsList) {
            state.techsList = []
            state.techsList = techsList
        },
        showTechsModal(state, newState) {
            state.modalVisible = newState
        }
    },
    actions: {
        getAllTechs({ commit }) {
            commit('loadingTechsStatus', true)

            return TechService.getAllTech()
                .then(result => {
                    commit('showAllTechs', result.data)
                    commit('loadingTechsStatus', false)
                })
                .catch(error => console.log(error))
        },
        addNewTech({commit}, data) {
            return TechService.createNewTech(data)
                .then(() => {
                    TechService.getAllTechs().then(
                        result => {
                            commit('showAllTechs', result.data)
                        }
                    )
                })
        },
        showModal({commit}) {
            commit('showTechsModal', true)
        },
        closeModal({commit}) {
            commit('showTechsModal', false)
        }

    },
    getters: {
        getAllTechs(state) {
            return state.techsList
        },
        isModalVisible(state) {
            return state.modalVisible
        },
        filterTech: (state) => (value) => {
             return state.techsList.filter(tech => tech.name.toLowerCase().includes(value.toLowerCase()))
        },
        getTechDataLoaded(state) {
            return state.modalTechsLoaded
        }

    },
    setters: {

    }
}
