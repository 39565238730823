<template>
<div class="authorization__container">
  <div v-if="resetSuccess" class="authorization__footer authorization__footer--flex-column authorization__footer--justify-center">
      <p class="authorization__footer-text">Если вы правильно ввели верный email, в ближайшее время вам на указанную почту поступит письмо</p>
      <p class="authorization__footer-text">Следуйте указаниям из письма для сброса пароля</p>
      <router-link class="authorization__footer-link" to="/login">Войти</router-link>
  </div>
  <div v-else>
    <h4>Сбросить пароль</h4>
      <div class="authorization__wrapper">
				<label for="userEmail">Введите ваш email</label>
        <input class="authorization__input" v-model.lazy="$v.userEmail.$model" id="userEmail" type="text" required>
        <div class="authorization__input--error-message" v-if="!$v.userEmail.email">Вы ввели невалидный email</div>
        <button v-on:click="resetPassword" class="authorization__submit">Восстановить пароль</button>
      </div>
  </div>
</div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators'
import AuthService from "@/services/auth.service"


export default {
  name: "RememberPass",
  data() {
    return {
      userEmail: '',
      resetSuccess: false
    }
  },
  validations: {
    userEmail: {
      required,
      email
    }
  },
  methods: {
    resetPassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
          AuthService.requestResetPassword(this.userEmail)
              .then(() => {
                  this.resetSuccess = true
              })
      }
    }
  }
  // methods: {
  //   restorePassword() {
  //
  //   }

}
</script>

<style scoped>

</style>