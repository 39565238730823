<template>
  <div class="home__content-block">
    <img src="home/2.png" alt="">
    <h2 class="home__content-block-title">Правила работы с сайтом</h2>
    <div class="home__content-block-text-wrap">
        <p class="home__content-block-text">
            Личный кабинет NTA разработан для упрощения взаимодействия с порталом. Теперь каждый автор самостоятельно может отправить свою публикацию на рассмотрение рецензеров и отслеживать изменение ее статуса в реестре.
          </p >
          <p class="home__content-block-text">
            Автор может добавить публикацию в реестр и при необходимости редактировать данные о ней в реестре.
          </p>
          <p class="home__content-block-text">
            Разместив публикацию в реестр удалить все данные о ней будет невозможно.
          </p>
          <p class="home__content-block-text">
            Так же каждый ТБ может вносить свои активности по ЦК NTA в реестр на страницу "Реестр активностей", которым потом будут присвоены баллы, от которых будет зависить место в рейтинге ТБ по NTA.
          </p>
          <p class="home__content-block-text">
            Пользователи сайта разделены на три группы "Авторы", "Рецензеры", "Главные редакторы". Изначально каждому пользователю будет присвоена роль "Автор", для ее изменнения можете обращаться к разработчикам на страницу "О команде".
          </p>
    </div>
  </div>
</template>

<script>


export default {
  name: "InfoBlockWorkRules",
}

</script>

<style scoped>

</style>
