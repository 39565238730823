import ArticleService from "@/services/articles.service"

const InitialState = {
        monitoredArticles: [],
        articles: [],
        articleDataLoaded: false,
        articleNextButton: false,
        articlePreviousButton: false,
    }

export default {
    namespaced: true,
    state: () => (InitialState),
    mutations: {
        getMonitored(state, articleData){
            state.monitoredArticles = articleData;
            state.articleNextButton = articleData.next != null;
            state.articlePreviousButton = articleData.previous != null;
        },
        getArticles (state, articleData) {
          state.articles = articleData.results;
          state.articleNextButton = articleData.next != null;
          state.articlePreviousButton = articleData.previous != null;
        },
        loadingArticlesStatus (state, newLoadingStatus) {
            state.articleDataLoaded = newLoadingStatus
        },
    },
    actions: {
        getArticles ({ commit }, query) {
            commit('loadingArticlesStatus', true)
            return ArticleService.getAllArticles(query)
                .then(result => {
                    commit('getArticles', result.data)
                    commit('loadingArticlesStatus', false)
                })
                .catch(error => console.log(error))
        },
        getMonitored ({ commit },query) {
            commit('loadingArticlesStatus', true)
            return ArticleService.getAllMonitoredArticles(query)
                .then(result => {
                    commit('getMonitored', result.data)
                    commit('loadingArticlesStatus', false)
                })
                .catch(error => console.log(error))
        },

    },
    getters: {
        getMonitoredArticles(state){
            return state.monitoredArticles
        },
        getArticleData(state) {
            return state.articles
        },
        getArticleDataLoaded(state) {
            return state.articleDataLoaded
        },
    }
}
