import { render, staticRenderFns } from "./textTypeFilterLinks.vue?vue&type=template&id=0ad5c6a4&scoped=true&"
import script from "./textTypeFilterLinks.vue?vue&type=script&lang=js&"
export * from "./textTypeFilterLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad5c6a4",
  null
  
)

export default component.exports