module.exports = {
    'statisticNewtworks': {
        'label': 'Площадка',
        'id': 'statisticPlatformSelect',
        'queryParamName': 'name',
        'select_list':   [
            {
                'selectValue': 'habr',
                'selectName': 'Хабр'
            },
            {
                'selectValue': 'vc',
                'selectName': 'VC'
            },
            {
                'selectValue': 'tg',
                'selectName': 'Telegram'
            },
            // {
            //     'selectValue': 'fb',
            //     'selectName': 'Facebook'
            // },
            {
                'selectValue': 'vk',
                'selectName': 'Вконтакте'
            }
        ]
    },
    'statisticIndex': {
        'label': 'Показатель',
        'id': 'statisticIndexSelect',
        'queryParamName': 'statistic_type',
        'select_list':
         [{  'selectValue': 'post_views',
             'selectName': 'Просмотры'},
          {  'selectValue':'post_likes',
             'selectName' : 'Лайки'
          }]
    },
    'statisticType': {
        'label': 'Тип рейтинга',
        'id': 'statisticTypeSelect',
        'select_list':
            [{ 'selectValue':'common',
                'selectName': 'Общая'},
             { 'selectValue':'dynamic' ,
               'selectName':'Динамика'}]
    }
}

// class statisticSelect {
//
// }
//
// class tableConfig {
//
// }
//
// class chartConfig {
//
// }