import moment from "moment";

export default new class {
    constructor() {
        this.state = {
            requestQuery: {
                startDate: '2021-09-01',
                endDate: moment(new Date()).format("YYYY-MM-DD"),
                statistic_type: 'post_views',
                name: 'habr',
            },
            requestAggregationType: 'common',
            statisticDataLoaded: false,
        }
        this.mutations = {
            updateRequestProperties(state, updateDatabject) {
                state.requestQuery[updateDatabject.keyToUpdate] = updateDatabject.valueToUpdate
            },
            updateStatisticType(state, value) {
                state.requestAggregationType = value
            }
        }
        this.actions = {
                setRequestProperties({commit, dispatch}, propertyToUpdate){
                    commit('updateRequestProperties', propertyToUpdate)
                    dispatch('getStatisticData')
                },
                setRequestType({commit, dispatch}, typeToUpdate){
                    commit('updateStatisticType', typeToUpdate)
                    dispatch('getStatisticData')
                }
        }
    }
}