<template>
  <div class="authorization">
    <div v-if="isResetCodeValid">
      <div class="authorization__container">
         <div v-if="isPasswordChanged">
           <h4>Ваш пароль успешно изменен</h4>
            <router-link class="authorization__footer-link" to="/login">Войти</router-link>
         </div>
         <div v-else>
           <h4>Ваш код сброса успешно подтвержден</h4>
           <h4>Введите новый пароль</h4>
            <div class="authorization__wrapper authorization__wrapper--medium">
              <label for="userPassword">Пароль</label>
              <input class="authorization__input" v-model.trim="$v.password.$model"  id="userPassword" type="password">
              <p class="authorization__input--error-message" v-if="!$v.password.required"> Вы не ввели пароль</p>
              <p class="authorization__input--error-message" v-if="!$v.password.minLength">Длина пароля должна быть не <br> меньше  {{ $v.password.$params.minLength.min }} символов</p>
              <label for="userPasswordCompare">Подтверждение пароля</label>
              <input class="authorization__input" v-model.trim="$v.repeatPassword.$model" id="userPasswordCompare" type="password">
              <p class="authorization__input--error-message" v-if="!$v.repeatPassword.sameAsPassword"> Пароли должны быть идентичны</p>
              <button v-on:click="resetPassword" class="authorization__submit">Сменить пароль</button>
            </div>
         </div>
      </div>
    </div>
    <div v-else>
         <div class="authorization__container">
              <h4>Ваш код сброса уcтарел или неверный</h4>
         </div>
    </div>
  </div>


</template>

<script>
import AuthService from "@/services/auth.service"
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
name: "ResetPass.vue",
  data() {
     return {
        password: '',
        repeatPassword: '',
        isResetCodeValid: false,
        isPasswordChanged: false
     }
  },
  created() {
      let verifyCode = this.$route.query.code
      if(verifyCode === undefined) {
         this.$router.push('/notfound')
      } else {
        AuthService.verifyResetCode(verifyCode)
            .then(() => {
                    this.isResetCodeValid = true
                }
            )
            // TODO доделать обработку ошибки если код неверный

      }
  },
   validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
      resetPassword() {
          this.$v.$touch();
           if (!this.$v.$invalid) {
             AuthService.resetUserPassword(this.$route.query.code, this.password)
                 .then(() => {
                      this.isPasswordChanged = true
                 })
           }


      }

  }
}
</script>

<style scoped>

</style>