<template>
     <div class="barchart__header-filter-params">
         <label for="filterPeriodFrom">Дата от:</label>
         <input id="filterPeriodFrom" type="date"
                :value="publishedStartDate"
                v-on:change="changeStartDate($event.target.value)">
         <label for="filterPeriodTo">Дата до:</label>
         <input id="filterPeriodTo" type="date"
                :value="publishedEndDate"
                v-on:change="changeEndDate($event.target.value)">

     </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "barchartFilterPeriod",
  computed: {
    ...mapState({
      publishedStartDate: state => state.articleRegistryPublishedState.startDate,
      publishedEndDate: state => state.articleRegistryPublishedState.endDate
    }),
  },
  methods: {
    changeStartDate(value) {
        this.$store.dispatch('articleRegistryPublishedState/changeStartDate', value);
    },
    changeEndDate(value) {
        this.$store.dispatch('articleRegistryPublishedState/changeEndDate', value);
    }
  }
}
</script>

<style scoped>

</style>