import AuthorService from "@/services/authors.service"


export const modalAuthors = {
    namespaced: true,
    state: () => ({
        authorsList: [],
        modalAuthorsLoaded: false,
        modalVisible: false,
    }),
    mutations: {
        loadingAuthorsStatus (state, newLoadingStatus) {
            state.modalAuthorsLoaded = newLoadingStatus
        },
        showAllAuthors(state, authorsList) {
            state.authorsList = []
            state.authorsList = authorsList
        },
        showAuthorsModal(state, newState) {
            state.modalVisible = newState
        }
    },
    actions: {
        getAllAuthors({ commit }) {
            commit('loadingAuthorsStatus', true)

            return AuthorService.getAllAuthors()
                .then(result => {
                    commit('showAllAuthors', result.data)
                    commit('loadingAuthorsStatus', false)
                })
                .catch(error => console.log(error))
        },
        addNewAuthor({commit}, data) {
            return AuthorService.createNewAuthor(data)
                .then(() => {
                    AuthorService.getAllAuthors().then(
                        result => {
                            commit('showAllAuthors', result.data)
                        }
                    )
                })
        },
        showModal({commit}) {
            commit('showAuthorsModal', true)
        },
        closeModal({commit}) {
            commit('showAuthorsModal', false)
        }

    },
    getters: {
        getAllAuthors(state) {
            return state.authorsList
        },
        isModalVisible(state) {
            return state.modalVisible
        },
        filterAuthor: (state) => (value) => {
             return state.authorsList.filter(author => author.name.toLowerCase().includes(value.toLowerCase()))
        },
        getAuthorDataLoaded(state) {
            return state.modalAuthorsLoaded
        }

    },
    setters: {

    }
}
