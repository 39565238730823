import Heading from './heading';

export default class Article {
  constructor(propsObject) {
    this.id = propsObject.id;
    this.author = propsObject.author || [];
    this.heading = propsObject.heading || undefined;
    this.status = propsObject.status || 'Новый';
    this.article_name = propsObject.article_name || '';
    this.dateAdded = propsObject.date_added || undefined;
    this.datePublished = propsObject.date_published || undefined;
    this.articleLink = propsObject.article_link || undefined;
    this.article_commentary = propsObject.article_commentary || undefined;
    this.tbResponsible = propsObject.tb_responsible || undefined;
    this.tbResponsibleName = propsObject.tb_responsible_name || null;
    this.tb = propsObject.tb || undefined;
    this.article_additional_commentary = propsObject.article_additional_commentary || undefined;
    this.article_additional_points = propsObject.article_additional_points || undefined;
    this.article_heading = propsObject.article_heading || new Heading();
    this.rule_tag = propsObject.rule_tag || [];
    this.tools_tag = propsObject.tools_tag || [];
    this.libs_tag = propsObject.libs_tag || [];
  }
}
